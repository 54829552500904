import "./style.css"
import UserTab, { UserTabConfig } from "../../../../components/tab/UserTab/UserTab"
import UserOrders from "../../../../components/order/user/UserOrders/UserOrders"

export default function UserOrdersPage() {
  return <div className="user_orders_page">
    <UserTab tabIndex={UserTabConfig.Orders.index}/>
    <UserOrders/>
  </div>
}
