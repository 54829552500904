import "./style.css"
import { useNavigate, useSearchParams } from "react-router-dom"
import { LOGIN__PATH, MAIN__PATH } from "../../../config/page_paths"
import { authOtpRequest } from "../../../api/auth/auth_api"
import { AuthCodeRequest } from "../../../models/auth/Auth"
import { useUser } from "../../Layout/Layout"
import { useDebugState } from "use-named-state"
import Button_, { ButtonStyle } from "../../../components/elements/Button/Button"
import Text_ from "../../../components/elements/Text/Text"
import OtpInput_ from "../../../components/elements/OtpInput/OtpInput"

const OTP_LENGTH = 6

export default function AuthOtpPage() {
  const { mutateUser } = useUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const urlCode = searchParams.get('code')

  const [code, setCode] = useDebugState<string | undefined>('code', undefined)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useDebugState<boolean>('submitButtonDisabled', true)

  if (!code && urlCode !== null) {
    setCode(urlCode)
    setSubmitButtonDisabled(false)
  }

  function handleSubmitOtp() {
    const email = localStorage.getItem('email')
    authOtpRequest({ email: email, code: code } as AuthCodeRequest)
      .then((response) => {
        const token = response.token
        if (token) {
          localStorage.setItem('token', token)
          mutateUser && mutateUser()
          navigate(MAIN__PATH)
        } else {
          navigate(LOGIN__PATH)
        }
      })
      .catch(e => {
        console.log('AuthOtpView.handleSubmitOtp: Error: ' + JSON.stringify(e))
        navigate(MAIN__PATH)
      })
  }

  function handleOtpChange(value: string) {
    setCode(value)
    if (value.length === OTP_LENGTH) {
      setSubmitButtonDisabled(false)
    } else {
      setSubmitButtonDisabled(true)
    }
  }

  return <div className='auth_otp_page'>
    <Text_ content="Enter One-Time Password to sign in" variant='subtitle1' paragraph={true} sx={{ textAlign: 'center' }}/>
    <OtpInput_ value={code} onChange={handleOtpChange} length={OTP_LENGTH}/>
    <Button_ buttonStyle={ButtonStyle.CONTAINED} disabled={submitButtonDisabled} onClickAny={handleSubmitOtp}>Sign In</Button_>
  </div>
}
