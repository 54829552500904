import "./style.css"
import { AddressRequest } from "../../../../models/Address"
import AddressAutocomplete from "../../../elements/AddressAutocomplete/AddressAutocomplete"
import { PageState } from "./PageState"
import Title from "./Title"
import TextField_ from "../../../elements/TextField/TextField"
import { useMobile } from "./utils"


interface Props {
  pageState: PageState
  disabled: boolean
  pickUpFrom?: AddressRequest
  setPickupFrom: (value: AddressRequest) => void
  deliveryTo?: AddressRequest
  setDeliveryTo: (value: AddressRequest) => void
}

export default function OrderCreateV2_1_Address(props: Props) {
  const addressConfig = {
    width: useMobile() ? "100%" : "600px", margin: "auto",
  }

  return <div style={{
    display: "flex", flexDirection: "column", rowGap: "0.75em", paddingBottom: "1.5em",
  }}>
    <Title text="Get Your Rate in Seconds" className="order_create_v2__address__title"/>
    {!props.disabled ? <AddressAutocomplete placeholder="From" onChange={props.setPickupFrom} style={addressConfig} className="order_create_v2__address__from_autocomplete"/> :
      <TextField_ variant="outlined" value={props.pickUpFrom?.full_address} style={addressConfig} className="order_create_v2__address__from"/>}
    {!props.disabled ? <AddressAutocomplete placeholder="To" onChange={props.setDeliveryTo} style={addressConfig} className="order_create_v2__address__to_autocomplete"/> :
      <TextField_ variant="outlined" value={props.deliveryTo?.full_address} style={addressConfig} className="order_create_v2__address__to"/>}
  </div>
}
