import React from "react"
import Title from "./Title"
import TextField_ from "../../../elements/TextField/TextField"
import Button_ from "../../../elements/Button/Button"
import { managerOrderSetUser } from "../../../../api/order/manager_order_api"
import { ManagerOrderSetUserRequest } from "../../../../models/order/ManagerOrder"


interface Props {
  disabled: boolean
  orderId: string
  email?: string
  setEmail: (value: string) => void
  mutateOrder: () => void
}

export default function ManagerOrderCreateV2_6_Email({ disabled, orderId, email, setEmail, mutateOrder }: Props) {
  return <div style={{
    display: "flex", flexDirection: "column", rowGap: "0.5em", paddingTop: "0.25em", paddingBottom: "1.5em",
  }}>
    <Title text="Please provide email to create an Account" className="order_create_v2__login__title"/>
    <TextField_ label="email" value={email} onChange={setEmail} disabled={disabled}/>
    <Button_
      label="Create an Account"
      disabled={disabled}
      onClick={() => managerOrderSetUser(orderId, { email: email } as ManagerOrderSetUserRequest).then(mutateOrder)}
    />
  </div>
}
