import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useDebugState } from "use-named-state"
import { meSetupIntentForOrders } from "../../../../../api/user/user_api"
import { OrderStatus } from "../../../../../models/order/OrderStatus"
import Submit_ from "../../../../elements/Submit/Submit"
import { useUser } from "../../../../../pages/Layout/Layout"
import { UserRole } from "../../../../../models/user/UserRole"
import { managerUserSetupIntentForOrders } from "../../../../../api/user/manager_user_api"
import { UserStripeSetupIntentResponse } from "../../../../../models/user/User"
import { OrderV2 } from "../../../../../models/order/OrderV2"

interface Props {
  order: OrderV2
  mutateOrder: () => void
}


export default function StripeSetupIntentForm({ order, mutateOrder }: Props) {
  const stripe = useStripe()
  const elements = useElements() || undefined
  const { user } = useUser()

  const [errorMessage, setErrorMessage] = useDebugState<string | undefined>("errorMessage", undefined)
  const [loading, setLoading] = useDebugState<boolean>("loading", false)

  function handleError(error: any) {
    setLoading(false)
    setErrorMessage(error.message)
  }

  function confirmSetupWithStripe(response: UserStripeSetupIntentResponse) {
    return stripe!!.confirmSetup({
      elements: elements, clientSecret: response.client_secret, confirmParams: { return_url: window.location.href },
    })
  }

  function waitOrderStatus() {
    setLoading(false)
    return new Promise(() => {
      let attempts = 0
      const checkStatus = setInterval(() => {
        attempts++
        mutateOrder()
        if (order.status === OrderStatus.PAYMENT_SETUP_SUCCESS || order.status === OrderStatus.PAYMENT_SETUP_FAILED || attempts >= 10) {
          clearInterval(checkStatus)
        }
      }, 500)
    })
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    if (!stripe) {
      return
    }

    setLoading(true)

    // @ts-ignore
    const { error: submitError } = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    if (user?.role !== UserRole.MANAGER) {
      meSetupIntentForOrders()
        .then((response: UserStripeSetupIntentResponse) => confirmSetupWithStripe(response))
        .then(() => waitOrderStatus())
    } else {
      if (order.user_id) {
        managerUserSetupIntentForOrders(order.user_id)
          .then((response: UserStripeSetupIntentResponse) => confirmSetupWithStripe(response))
          .then(() => waitOrderStatus())
      } else {
        console.error("Order User ID was not set. Can't setup the User Intent from Managers role.")
      }
    }
  }

  return <>
    <form onSubmit={handleSubmit}>
      <PaymentElement/>
      <Submit_ onClick={handleSubmit} isUploading={!stripe || loading}/>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  </>
}
