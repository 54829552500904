import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface Props {
  label: string
  value: string
  onChange: (value: any) => void
  isValid?: boolean
  className?: string
  isRequired?: boolean
  padding?: string
}

export default function DateTimePicker_({ label, value, onChange, isValid, className, isRequired, padding }: Props) {
  return <div className={className}>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}

    >
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          label={label}
          value={value}
          onChange={(value: any) => onChange(dayjs.utc((value)))}
          timezone='UTC'
          sx={{
            border: isValid === false ? '1px solid red' : '', borderRadius: isValid ? '' : '6px', ".MuiInputBase-input": {
              padding: "0", height: "3em", marginTop: "0.3em"

            }, ".MuiOutlinedInput-input": {
              padding: "0", paddingLeft: "1em", // marginTop: "0.5em"
              // paddingTop: "1.1em",
              // height: "1.5em"
            }, ".MuiIconButton-root": {
              padding: "0.25em", paddingTop: "0.45em"
            }, ".MuiOutlinedInput-notchedOutline": {
              top: 0
            }
          }}
          slotProps={{ textField: { required: !isRequired ? false : isRequired } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  </div>
}
