import { LineColor, PolylineData } from './PolylineData'
import { PolygonColor, PolygonData } from './PolygonData'
import { DELIVERY_GIFT_ICO, DELIVERY_TO_ICON, DRIVER_ICO, Marker, PICKUP_FROM_ICON, PICKUP_GIFT_ICO } from './Marker'
import { MarkerType } from './MarkerType'
import { BaseOrder } from '../../../models/order/BaseOrder'
import { Coordinate } from '../../../models/Coordinate'
import { OrderV2 } from "../../../models/order/OrderV2";

const CURRENT_LINE_COLOR = {
  strokeColor: 'red', strokeOpacity: 0.7, strokeWeight: 5.0,
} as LineColor

const AVAILABLE_LINE_COLOR = {
  strokeColor: 'green', strokeOpacity: 0.7, strokeWeight: 4.0,
} as LineColor

const ACTIVE_LINE_COLOR = {
  strokeColor: 'blue', strokeOpacity: 0.7, strokeWeight: 5.0,
} as LineColor

const CURRENT_POLYGON_COLOR = {
  strokeColor: '#FF0000', strokeOpacity: 2.8, strokeWeight: 2, fillColor: '#FF0000', fillOpacity: 0,
} as PolygonColor

export function buildEncodedPolylines(currentPolyline?: string, availablePolylines?: string[], activePolylines?: string[],): PolylineData[] {
  const polylines: PolylineData[] = []

  if (currentPolyline) {
    polylines.push({
      encodedPolyline: currentPolyline, lineColor: CURRENT_LINE_COLOR,
    } as PolylineData)
  }

  availablePolylines?.forEach(availablePolyline => {
    polylines.push({
      encodedPolyline: availablePolyline, lineColor: AVAILABLE_LINE_COLOR,
    } as PolylineData)
  })

  activePolylines?.forEach(activePolyline => {
    polylines.push({
      encodedPolyline: activePolyline, lineColor: ACTIVE_LINE_COLOR,
    } as PolylineData)
  })

  return polylines
}

export function buildEncodedPolygons(polygons: string[]): PolygonData[] {
  return polygons.map(polygon => {
    return {
      encodedPolygon: polygon, polygonColor: CURRENT_POLYGON_COLOR,
    } as PolygonData
  })
}


function buildPickupMarker(entityId: string, lat: number, lng: number, type: MarkerType, payload?: any,): Marker {
  return {
    markerId: `${entityId}_pickup`,
    entityId: entityId,
    lat: lat,
    lng: lng,
    label: '',
    color: 'green',
    url: '',
    type: type,
    icon: type === MarkerType.AVAILABLE_ORDER ? PICKUP_GIFT_ICO : PICKUP_FROM_ICON,
    payload: payload,
  }
}

function buildDeliveryMarker(entityId: string, lat: number, lng: number, type: MarkerType, payload?: any,): Marker {
  return {
    markerId: `${entityId}_delivery`,
    entityId: entityId,
    lat: lat,
    lng: lng,
    label: '',
    color: 'red',
    url: '',
    type: type,
    icon: type === MarkerType.AVAILABLE_ORDER ? DELIVERY_GIFT_ICO : DELIVERY_TO_ICON,
    payload: payload,
  }
}

function buildDriverMarker(entityId: string, coordinate: Coordinate, payload?: any,): Marker {
  return {
    markerId: `${entityId}_driver`, entityId: entityId, lat: coordinate.lat, lng: coordinate.lng, label: '', color: 'red', url: '', type: MarkerType.UNIT, icon: DRIVER_ICO, payload: payload,
  }
}

export function buildPolylines(order?: BaseOrder | OrderV2): PolylineData[] | undefined {
  if (order) {
    return buildEncodedPolylines(order.distance_data.encoded_polyline)
  }
}

export function buildMarkers(currentOrders?: BaseOrder[] | OrderV2[], availableOrders?: BaseOrder[],): Marker[] {
  const markers: Marker[] = []
  currentOrders?.forEach((order: BaseOrder | OrderV2) => {

    markers.push(buildPickupMarker(order.order_id, order.pickup_from.coordinate.lat, order.pickup_from.coordinate.lng, MarkerType.CURRENT_ORDER, { 'order': order },))

    markers.push(buildDeliveryMarker(order.order_id, order.delivery_to.coordinate.lat, order.delivery_to.coordinate.lng, MarkerType.CURRENT_ORDER, { 'order': order },))

    if (order.driver_coordinate) {
      buildDriverMarker(order.order_id, order.driver_coordinate, { 'order': order },)
    }
  })

  availableOrders?.forEach((order: BaseOrder) => {

    markers.push(buildPickupMarker(order.order_id, order.pickup_from.coordinate.lat, order.pickup_from.coordinate.lng, MarkerType.AVAILABLE_ORDER, { 'order': order },))

    // markers.push(
    //   buildDeliveryMarker(
    //     order.order_id,
    //     order.delivery_to.coordinate.lat,
    //     order.delivery_to.coordinate.lng,
    //     MarkerType.AVAILABLE_ORDER,
    //     { 'order': order },
    //   )
    // )

  })

  return markers
}
