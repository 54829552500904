import { Box } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import { ReactNode } from 'react'

interface Props {
  onChange?: (event: any, value: any) => void
  value?: number
  content: ReactNode
}

export default function Tabs_({ onChange, value, content }: Props) {
  return <>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={onChange} scrollButtons allowScrollButtonsMobile variant='scrollable'>
        {content}
      </Tabs>
    </Box>
  </>
}
