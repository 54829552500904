import { MuiTelInput } from 'mui-tel-input'

interface Props {
  label: string
  value?: string
  onChange?: (value: any) => void
  required?: boolean
  className?: string
  height?: string
}

export default function Tel({ label, value, onChange, required, className, height }: Props) {
  function buildSx() {
    if (height !== undefined) {
      return {
        '& .MuiOutlinedInput-root': {
          height: height
        }
      }
    } else {
      return undefined
    }
  }

  return <>
    <MuiTelInput
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      disabled={onChange === undefined}
      variant={onChange ? 'outlined' : 'filled'}
      InputLabelProps={{ shrink: true }}
      autoComplete='tel'
      defaultCountry='US'
      className={className}
      sx={buildSx()}
    />
  </>
}
