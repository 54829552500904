import './style.css'
import DriverMap from '../../order/driver/DriverMap/DriverMap'
import DriverOrdersTable from '../../order/driver/DriverOrdersTable/DriverOrdersTable'

export default function DriverDashboard() {
  return <div className='driver_dashboard__fields'>
    <DriverMap/>
    <div className='driver_dashboard__orders_table'>
      <DriverOrdersTable availableOnly={true}/>
    </div>
  </div>
}
