import './style.css'
import { getBackendUrl } from '../../../../config/env'
import PDFViewer from '../../../pdf/PDFViewer/PDFViewer'
import Divider_ from '../../../elements/Divider/Divider'
import { AdminAgreement } from '../../../../models/agreement/AdminAgreement'
import Text_ from "../../../elements/Text/Text"
import CloudDownloadIcon_ from "../../../elements/icons/CloudDownloadIcon"

interface Props {
  agreement: AdminAgreement
}

export default function AdminAgreementCardDocumentView({ agreement }: Props) {
  return <>
    <Divider_ text='Document view' className='agreement_card__divider__document_view'/>
    <PDFViewer pdfUrl={`${getBackendUrl()}/admin/agreements/${agreement.agreement_id}/file`}/>
    <a href={`${getBackendUrl()}/agreements/${agreement.agreement_id}/file`} className='agreement_card__download_pdf'>
      <CloudDownloadIcon_/>
      <Text_ content="Download PDF"/>
    </a>
  </>
}
