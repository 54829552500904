import "./style.css"
import Checkbox_ from "../../../elements/Checkbox/Checkbox"
import Tel from "../../../elements/Tel/Tel"
import Title from "./Title"
import { ReactNode } from "react"
import DateTimePicker_ from "../../../elements/DateTimePicker/DateTimePicker"
import { useMobile } from "./utils"
import Text_ from "../../../elements/Text/Text"


interface Props {
  disabled: boolean
  userRate: number,
  pickupAtAsap: boolean
  setPickupAtAsap: (value: boolean) => void
  pickupAt: any
  setPickupAt: (value: any) => void
  pickupContactless: boolean
  setPickupContactless: (value: boolean) => void
  pickupPhoneNumber?: string
  setPickupPhoneNumber: (value: string) => void
  deliveryAt: any
  setDeliveryAt: (value: any) => void
  deliveryAtAsap: boolean
  setDeliveryAtAsap: (value: boolean) => void
  deliveryContactless: boolean
  setDeliveryContactless: (value: boolean) => void
  deliveryPhoneNumber?: string
  setDeliveryPhoneNumber: (value: string) => void
}

export default function OrderCreateV2_3_PickupAndDeliveryDetails({
                                                                   disabled,
                                                                   userRate,
                                                                   pickupAtAsap,
                                                                   setPickupAtAsap,
                                                                   pickupAt,
                                                                   setPickupAt,
                                                                   pickupContactless,
                                                                   setPickupContactless,
                                                                   pickupPhoneNumber,
                                                                   setPickupPhoneNumber,
                                                                   deliveryAt,
                                                                   setDeliveryAt,
                                                                   deliveryAtAsap,
                                                                   setDeliveryAtAsap,
                                                                   deliveryContactless,
                                                                   setDeliveryContactless,
                                                                   deliveryPhoneNumber,
                                                                   setDeliveryPhoneNumber,
                                                                 }: Props) {

  function cell(children: ReactNode): ReactNode {
    return <>
      <div style={{ flex: 1, display: "flex", justifyContent: "center", padding: "0.15em" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "270px", rowGap: "0.25em" }}>
          {children}
        </div>
      </div>
    </>
  }

  const checkboxStyle = {
    // paddingLeft: "3em"
  }

  return <div style={{
    display: "flex", flexDirection: "column", margin: "auto", paddingTop: "0.25em", paddingBottom: "1.5em",
  }}>
    <Title text="Here is your rate" className="order_create_v2__pickup_and_delivery_details__rate_title"/>
    <Text_
      sx={{
        alignSelf: "center", fontSize: "96px", fontWeight: "700", fontFamily: "Work Sans", color: "#60CE5E"
      }}
      className="order_create_v2__pickup_and_delivery_details__rate"
    >
      ${userRate}
    </Text_>
    <Title text="Let's arrange pickup & delivery"
           className="order_create_v2__pickup_and_delivery_details__details_title"/>
    <div style={{
      display: "flex", flexDirection: "column", width: useMobile() ? "100%" : "570px", margin: "auto", marginLeft: useMobile() ? "auto" : "13.5em",
    }}>
      <div style={{ display: "flex", flexDirection: useMobile() ? "column" : "row" }}>
        {cell(<>
          <div style={checkboxStyle}>
            <Checkbox_ label="Pickup ASAP" value={pickupAtAsap} onChange={setPickupAtAsap} disabled={disabled}
                       className="order_create_v2__pickup_and_delivery_details__pickup_at_asap__checkbox"/>
          </div>
          {!pickupAtAsap && <div style={{ marginTop: "0" }}><DateTimePicker_ label="Pickup at" value={pickupAt} onChange={setPickupAt}/>
          </div>}
        </>)}
        {cell(<>
          <div style={checkboxStyle}>
            <Checkbox_ label="Delivery ASAP" value={deliveryAtAsap} onChange={setDeliveryAtAsap} disabled={disabled}
                       className="order_create_v2__pickup_and_delivery_details__delivery_at_asap__checkbox"/>
          </div>
          {!deliveryAtAsap && <DateTimePicker_ label="Delivery at" value={deliveryAt} onChange={setDeliveryAt}/>}
        </>)}
      </div>
      <div style={{ display: "flex", flexDirection: useMobile() ? "column" : "row" }}>
        {cell(<>
          <div style={checkboxStyle}>
            <Checkbox_ label="Pickup contactless" value={pickupContactless} onChange={setPickupContactless}
                       disabled={disabled}
                       className="order_create_v2__pickup_and_delivery_details__pickup_contactless__checkbox"/>
          </div>
          {!pickupContactless && <Tel label="Pickup phone number" value={pickupPhoneNumber} onChange={setPickupPhoneNumber} height="3em"/>}
        </>)}
        {cell(<>
          <div style={checkboxStyle}>
            <Checkbox_ label="Delivery contactless" value={deliveryContactless} onChange={setDeliveryContactless}
                       disabled={disabled}
                       className="order_create_v2__pickup_and_delivery_details__delivery_contactless__checkbox"/>
          </div>
          {!deliveryContactless && <Tel label="Delivery phone number" value={deliveryPhoneNumber} onChange={setDeliveryPhoneNumber}
                                        height="3em"/>}
        </>)}
      </div>
    </div>
  </div>
}
