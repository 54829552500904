import "./style.css"
import OrderShortDetailsWithTimeline from "../../OrderShortDetailsWithTimeline/OrderShortDetailsWithTimeline"
import OrderFilesSwipeableView from "../../OrderFilesSwipeableView/OrderFilesSwipeableView"
import OrderCardPickupInfo from "../.././card_info/OrderCardPickupInfo/OrderCardPickupInfo"
import OrderCardDeliveryInfo from "../../card_info/OrderCardDeliveryInfo/OrderCardDeliveryInfo"
import OrderCardRouteInfo from "../../card_info/OrderCardRouteInfo/OrderCardRouteInfo"
import OrderCardPackageInfo from "../../card_info/OrderCardPackageInfo/OrderCardPackageInfo"
import OrderCardContactInfo from "../../card_info/OrderCardContactInfo/OrderCardContactInfo"
import OrderCardBaseInfo from "../../card_info/OrderCardBaseInfo/OrderCardBaseInfo"
import Divider_ from "../../../elements/Divider/Divider"
import CircularProgress_ from "../../../elements/CircularProgress/CircularProgress_"
import { useManagerOrder } from "../../../../api/order/manager_order_api"

interface Props {
  orderId: string
}

export default function ManagerOrderCard({ orderId }: Props) {
  const { data: order } = useManagerOrder(orderId)
  return <>
    {order ? <>
      <Divider_ text="Order overview" className="manager_order_card__divider__overview"/>

      <div className="manager_order_card__details">
        <OrderShortDetailsWithTimeline orderId={order.order_id} orderStatus={order.status}/>
        {order.files && <OrderFilesSwipeableView orderId={order.order_id} files={order.files}/>}
      </div>

      <div className="driver_order_card__fields">
        <OrderCardPickupInfo order={order}/>
        <OrderCardDeliveryInfo order={order}/>
        <OrderCardRouteInfo order={order}/>
        <OrderCardPackageInfo order={order}/>
        <OrderCardContactInfo order={order}/>
        <OrderCardBaseInfo order={order}/>
      </div>
    </> : <CircularProgress_/>}
  </>
}
