import './style.css'
import TextField_ from '../../../elements/TextField/TextField'
import { packagesToString } from '../../../../utils/package_utils'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import Divider_ from '../../../elements/Divider/Divider'

interface Props {
  order: BaseOrder
}

export default function OrderCardPackageInfo({ order }: Props) {
  return <>
    <Divider_ text='Package info' className='order_card__divider__package_info'/>
    <TextField_ label='Packages' value={packagesToString(order.packages)} className='order_card__packages'/>
  </>
}
