import { http } from "../http"
import {
  OrderCreateV2_AddressAndPackagesRequest,
  OrderUpdateV2_ConfirmAndAgreementRequest,
  OrderUpdateV2_DetailsAndPhotosRequest,
  OrderV2
} from "../../models/order/OrderV2"


export function orderGetV2(orderId: string): Promise<OrderV2> {
  return http.getData(`/v2/orders/${orderId}`)
}

export function orderCreateV2_draft(request: OrderCreateV2_AddressAndPackagesRequest): Promise<OrderV2> {
  return http.postData("/v2/orders/draft", request)
}

export function orderUpdateV2_pickupAndDeliveryDetailsAndPhotos(orderId: string, request: OrderUpdateV2_DetailsAndPhotosRequest, files?: FileList): Promise<OrderV2> {
  return http.putMultipartData(`/v2/orders/${orderId}/details-and-photos`, request, files)
}

export function orderGetV2_confirmAndHold(orderId: string, returnUrl: string, request: OrderUpdateV2_ConfirmAndAgreementRequest): Promise<OrderV2> {
  return http.putData(`/v2/orders/${orderId}/hold?return_url=${returnUrl}`, request)
}

export function orderGetV2_setUser(orderId: string): Promise<OrderV2> {
  return http.putData(`/v2/orders/${orderId}/set-user`)
}
