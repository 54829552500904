import "./style.css"
import Divider_ from "../../../elements/Divider/Divider"
import AgreementCardDocumentInfo from "../../card_info/AgreementCardDocumentInfo/AgreementCardDocumentInfo"
import AgreementCardDocumentView from "../../card_info/AgreementCardDocumentView/AgreementCardDocumentView"
import CircularProgress_ from "../../../elements/CircularProgress/CircularProgress_"
import { useManagerAgreementActive } from "../../../../api/agreement/manager_agreement_api"

interface Props {
  agreementId: string
}

export default function ManagerAgreementCard({ agreementId }: Props) {
  const { data: agreement } = useManagerAgreementActive(agreementId)
  return <>
    {agreement ? <div className="manager_agreement_card__fields">
      <Divider_ text={`Agreement overview: ${agreementId}`} className="manager_agreement_card__divider__overview"/>
      <AgreementCardDocumentInfo agreement={agreement}/>
      <AgreementCardDocumentView agreement={agreement}/>
    </div> : <CircularProgress_/>}
  </>
}
