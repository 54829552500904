import useSWR, { SWRResponse } from 'swr'
import { http } from '../http'
import { AdminUser, AdminUserUpdateRequest } from '../../models/user/AdminUsers'
import { PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'

export function useAdminUser(userId: string): SWRResponse<AdminUser> {
  return useSWR<AdminUser>([`/admin/users/${userId}`])
}

export function useAdminUsers(page?: number, pageSize?: number): SWRResponse<PageResponse<AdminUser>> {
  return usePageable('/admin/users/search', {page: page, page_size: pageSize})
}

export function adminUserUpdate(userId: string, request: AdminUserUpdateRequest): Promise<AdminUser> {
  return http.putData<AdminUserUpdateRequest, AdminUser>(`/admin/users/${userId}`, request)
}

export function adminUserRejectDriver(userId: string): Promise<AdminUser> {
  return http.putData<any, AdminUser>(`/admin/users/${userId}/reject-driver`)
}

export function adminUserAcceptDriver(userId: string): Promise<AdminUser> {
  return http.putData<any, AdminUser>(`/admin/users/${userId}/accept-driver`)
}

export function adminUserResetDriverStatus(userId: string): Promise<AdminUser> {
  return http.putData<any, AdminUser>(`/admin/users/${userId}/reset-driver-status`)
}

export function adminUserStripeConnectAccountDelete(userId: string): Promise<AdminUser> {
  return http.putData<any, AdminUser>(`/admin/users/${userId}/delete-stripe-connect-account`)
}
