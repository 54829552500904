import Button_ from "../../../elements/Button/Button"
import Title from "./Title"
import { MAIN__PATH } from "../../../../config/page_paths"
import Text_ from "../../../elements/Text/Text"


export default function OrderCreateV2_9_Done() {
  return <div style={{
    display: "flex", flexDirection: "column", paddingTop: "0.25em", paddingBottom: "1.5em",
  }}>
    <Title text="You're all set!" className="order_create_v2__done__title"/>
    <Text_ content="We're searching for the nearest driver to Deliver your
      package." sx={{ margin: "auto", marginTop: "0.5em" }}/>
    <Text_ content="You'll receive an update soon." sx={{ margin: "auto", marginBottom: "2em" }}/>
    <div style={{ margin: "auto" }}>
      <Button_ label={"Visit Dashboard"} navigateTo={MAIN__PATH} />
    </div>
  </div>
}
