import "./style.css"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import Container from "@mui/material/Container"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import { Link } from "react-router-dom"
import {
  ABOUT_US__PATH, BECOME_A_DRIVER, DRIVER__ROUTE_CREATE__PATH, HELP__PATH, LOGIN__PATH, LOGOUT__PATH, MAIN__PATH, ORDER_TRACKS__PATH, PROFILE__PATH, USER__ORDER_CREATE_V2__PATH
} from "../../../config/page_paths"
import UserRequestMissingInfo from "../../user/user/UserRequestMissingInfo/UserRequestMissingInfo"
import { User } from "../../../models/user/User"
import { useDebugState } from "use-named-state"
import { useUser } from "../../../pages/Layout/Layout"
import { UserRole } from "../../../models/user/UserRole"
import { Box } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import RouteIcon from "@mui/icons-material/Route"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import { ReactNode, useEffect } from "react"
import SearchIcon from "@mui/icons-material/Search"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"
import ImportContactsIcon from "@mui/icons-material/ImportContacts"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import { recordCoordinate } from "../../../api/user/driver_user_api"
import { DriverUserCoordinateRequest } from "../../../models/user/DriverUserCoordinateRequest"

const COORDINATE_CHECK_INTERVAL_SEC = 60

interface ButtonModel {
  title: string
  path: string
  icon?: ReactNode
}

export default function Header() {
  const { user } = useUser()
  const role = user?.selected_role || user?.role
  const [anchorLeftButtons, setAnchorLeftButtons] = useDebugState<HTMLElement | null>("anchorLeftButtons", null)
  const [anchorAvatarMenu, setAnchorAvatarMenu] = useDebugState<HTMLElement | null>("anchorAvatarMenu", null)

  function buildNavigationTabs(user?: User): ButtonModel[] {
    const items: any[] = []

    if (user) {
      if (role === UserRole.DRIVER) {
        items.push({
          title: "New Route", path: DRIVER__ROUTE_CREATE__PATH, icon: <RouteIcon/>,
        } as ButtonModel)
      }
    }

    items.push({
      title: "Rate & Ship", path: USER__ORDER_CREATE_V2__PATH, icon: <LocalShippingIcon/>,
    } as ButtonModel)
    if (role !== UserRole.ADMIN && role !== UserRole.MANAGER) {
      items.push({
        title: "Track", path: ORDER_TRACKS__PATH, icon: <SearchIcon/>,
      } as ButtonModel)
    }
    if (role !== UserRole.ADMIN) {
      items.push({
        title: "Help", path: HELP__PATH, icon: <SupportAgentIcon/>,
      } as ButtonModel)
    }

    if (user?.driver_status === undefined) {
      items.push({
        title: "Become a Driver", path: BECOME_A_DRIVER, icon: < PersonAddIcon/>,
      } as ButtonModel)
    }

    items.push({
      title: "About Us", path: ABOUT_US__PATH, icon: <ImportContactsIcon/>
    } as ButtonModel)
    return items
  }

  function extractLetter(name?: string) {
    if (!name) {
      return "?"
    } else {
      const trimmed = name.trim()
      return Array.from(trimmed)[0].toUpperCase()
    }
  }

  function chooseColor() {
    if (user) {
      if (role === UserRole.ADMIN) {
        return "#b26a00" // Orange
      }
      if (role === UserRole.DRIVER) {
        return "#4caf50" // Green
      }
      if (role === UserRole.MANAGER) {
        return "#c14d36" // Red
      }
    }
    return "rgb(25, 118, 210)" // Blue
  }

  function requiredExtraInfo(user?: User): boolean {
    if (user) {
      if (role === UserRole.USER || role === UserRole.DRIVER) {
        return !user.name || !user.phone_number
      } else {
        // No required info to admin
        return false
      }
    } else {
      return false
    }
  }

  function calculateMobileIconOffset(): number {
    if (user !== undefined) {
      return 1
    } else {
      return 9
    }
  }

  function handleOpenNavMenu(event: React.MouseEvent<HTMLElement>): void {
    setAnchorLeftButtons(event.currentTarget)
  }

  function handleOpenUserMenu(event: React.MouseEvent<HTMLElement>): void {
    setAnchorAvatarMenu(event.currentTarget)
  }

  function handleCloseNavMenu(): void {
    setAnchorLeftButtons(null)
  }

  function handleCloseAvatarMenu(): void {
    setAnchorAvatarMenu(null)
  }

  function buildAvatarMenu(user?: User): ReactNode {
    const menu = []
    if (user) {
      menu.push({ title: "Profile", path: PROFILE__PATH, className: "header__avatar_menu__profile" })
      menu.push({ title: "Log Out", path: LOGOUT__PATH, className: "header__avatar_menu__log_out" })
    } else {
      menu.push({ title: "Log In", path: LOGIN__PATH, className: "header__avatar_menu__log_in" })
    }

    return menu.map(item => <MenuItem key={item.title} component={Link} to={item.path} onClick={handleCloseAvatarMenu}>
      <Typography className={item.className}>{item.title}</Typography>
    </MenuItem>)
  }

  function handleRecordCoordinate(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        recordCoordinate({
          lat: position.coords.latitude, lng: position.coords.longitude, accuracy: position.coords.accuracy

        } as DriverUserCoordinateRequest)
      }, error => {
        console.error("Header.handleRecordCoordinate.getCurrentPosition: Error getting location:", error)
      })
    } else {
      console.error("Header.handleRecordCoordinate.getCurrentPosition: Geolocation is not supported by this browser.")
    }
  }

  const [trackingLocation, setTrackingLocation] = useDebugState<boolean>("trackingLocation", false)

  useEffect(() => {
    if (user && role === UserRole.DRIVER) {
      if (!trackingLocation) {
        setTrackingLocation(true)
        handleRecordCoordinate()
        const intervalId = setInterval(() => {
          handleRecordCoordinate()
        }, COORDINATE_CHECK_INTERVAL_SEC * 1000)
        return () => clearInterval(intervalId)
      }
    }
  }, [user])

  return <header>
    {requiredExtraInfo(user) && <UserRequestMissingInfo/>}
    <AppBar sx={{ bgcolor: chooseColor() }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* WEB - ICON */}
          <IconButton component={Link} to={MAIN__PATH} className="header__web_icon" sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <img alt="logo_white" src="/images/logos/logo_white.svg"/>
          </IconButton>

          {/* WEB - MENU STYLE */}
          <Typography variant="h6" noWrap component="a" href="#app-bar-with-responsive-menu"
                      sx={{ mr: 2, display: { xs: "none", md: "flex" }, fontFamily: "monospace", fontWeight: 700, letterSpacing: ".3rem", color: "inherit", textDecoration: "none" }}/>

          {/* MOBILE - BUTTONS HAMBURGER */}
          <Box
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon/>
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorLeftButtons} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} keepMounted transformOrigin={{ vertical: "top", horizontal: "left" }}
                  open={Boolean(anchorLeftButtons)} onClose={handleCloseNavMenu}
                  sx={{ display: { xs: "block", md: "none" } }}
            >
              {buildNavigationTabs(user).map(tab => {
                return <MenuItem key={tab.title} onClick={handleCloseNavMenu}>
                  <Typography sx={{ textDecoration: "none", color: "black", fontWeight: 500 }} component={Link} to={tab.path}>{tab.title}</Typography>
                </MenuItem>
              })}
            </Menu>
          </Box>

          {/* MOBILE - ICON */}
          <IconButton component={Link} to={MAIN__PATH} sx={{ display: { xs: "flex", md: "none" }, ml: calculateMobileIconOffset() }}>
            <img alt="logo_white" src="/images/logos/logo_white.svg"/>
          </IconButton>

          {/* MOBILE - ICON ALIGNMENT (MIDDLE) */}
          <Typography variant="h5" noWrap component="a" href="#app-bar-with-responsive-menu"
                      sx={{ mr: 2, display: { xs: "flex", md: "none" }, flexGrow: 1, fontFamily: "monospace", fontWeight: 700, letterSpacing: ".3rem", color: "inherit", textDecoration: "none" }}/>

          {/* WEB: BUTTONS */}
          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, columnGap: "1em" }}>
            {buildNavigationTabs(user).map(tab => <Button
              sx={{
                my: 2, color: "white", textAlign: "center", fontWeight: 600,
              }}
              key={tab.title}
              component={Link}
              to={tab.path}
              startIcon={tab.icon}
            >
              {tab.title}
            </Button>)}
          </Box>

          {/* WEB+MOBILE: AVATAR MENU */}
          <Box
            sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              {user ? <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className="header__avatar_menu">
                <Avatar>{extractLetter(user?.name)}</Avatar>
              </IconButton> : <>
                <Button sx={{ my: 2, color: "white", display: "block", textAlign: "center", fontWeight: 600 }} key="login" component={Link} to={LOGIN__PATH}>Sign In / Up</Button>
              </>}
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorAvatarMenu}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorAvatarMenu)} onClose={handleCloseAvatarMenu}
              sx={{ mt: "45px" }}
            >
              {buildAvatarMenu(user)}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  </header>
}
