import "./style.css"
import Title from "./Title"
import { useEffect } from "react"
import FileInput, { FileInputType } from "../../../elements/FileInput/FileInput"
import SwipeableView from "../../../elements/SwipeableView/SwipeableView"
import { PhotoAndLabel } from "../../../elements/SwipeableView/PhotoAndLabel"
import { useDebugState } from "use-named-state"
import Button_ from "../../../elements/Button/Button"


interface Props {
  disabled: boolean
  photosDecision: boolean
  files?: FileList
  setFiles: (value: FileList) => void
  onProceedClick: () => void
}

export default function OrderCreateV2_5_PhotosUploadAndProceed({
                                                                 disabled, photosDecision, files, setFiles, onProceedClick,
                                                               }: Props) {

  const [photosAndLabels, setPhotosAndLabels] = useDebugState<PhotoAndLabel[]>("photosAndLabels", [])

  function readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result.toString().split(",")[1])
        } else {
          reject(new Error("Failed to read file"))
        }
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  async function processFiles(files: FileList): Promise<PhotoAndLabel[]> {
    const photosAndLabels: PhotoAndLabel[] = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const base64 = await readFileAsBase64(file)
      photosAndLabels.push({ label: file.name, binary: base64 })
    }
    return photosAndLabels
  }

  useEffect(() => {
    async function handleFiles(): Promise<void> {
      if (files) {
        setPhotosAndLabels(await processFiles(files))
      } else {
        setPhotosAndLabels([])
      }
    }

    handleFiles()
  }, [files])


  return <div style={{
    display: "flex", flexDirection: "column", margin: "auto", paddingTop: "0.25em", paddingBottom: "1.5em",
  }}>
    {photosDecision && (!files || files?.length === 0) && <div style={{ margin: "auto" }}>
      <FileInput label="Upload Photos" type={FileInputType.IMAGE} files={files} onChange={setFiles}
                 enablePreview={false} disabled={disabled}/>
    </div>}
    {photosAndLabels.length > 0 && <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Title text="Great! Your photo(s) have been uploaded successfully:"
             className="order_create_v2__photos_upload_and_proceed__title"/>
      <SwipeableView photosAndLabels={photosAndLabels} showLabel={false}/>
    </div>}
    {<div style={{ margin: "auto", marginTop: "2em" }}>
      <Button_ label="Proceed to checkout" onClickVoid={onProceedClick} disabled={disabled}
               className="order_create_v2__photos_upload_and_proceed__proceed_button"/>
    </div>}
  </div>
}
