import './style.css'
import { useParams } from 'react-router-dom'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'
import AdminAgreementCard from '../../../../components/agreement/admin/AdminAgreementCard/AdminAgreementCard'

export default function AdminAgreementCardPage() {
  const { agreementId } = useParams()
  return <div className='admin_agreement_card_page'>
    <AdminTab tabIndex={AdminTabConfig.Agreements.index}/>
    <AdminAgreementCard agreementId={agreementId as string}/>
  </div>
}
