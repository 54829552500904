import React from "react"
import { getBackendUrl } from "../../../../config/env"
import { Google } from "@mui/icons-material"
import { useDebugState } from "use-named-state"
import { authOtpRequest, authRequest } from "../../../../api/auth/auth_api"
import { AuthCodeRequest, AuthRequest } from "../../../../models/auth/Auth"
import { useUser } from "../../../../pages/Layout/Layout"
import Title from "./Title"
import Button_, { ButtonStyle } from "../../../elements/Button/Button"
import Text_ from "../../../elements/Text/Text"
import OtpInput_ from "../../../elements/OtpInput/OtpInput"
import TextField_ from "../../../elements/TextField/TextField"


const OTP_LENGTH = 6

interface Props {
  email?: string
  setEmail: (value: string) => void
  disableCondition: boolean
}

export default function UserOrderCreateV2_6_Login({ email, setEmail, disableCondition }: Props) {
  const { mutateUser } = useUser()
  const [authButtonDisabled, setAuthButtonDisabled] = useDebugState<boolean>("authButtonDisabled", true)

  function handleOnEmailChange(value: string) {
    setEmail(value)
    if (value !== "") {
      setAuthButtonDisabled(false)
      localStorage.setItem("email", value)
    } else {
      setAuthButtonDisabled(true)
      localStorage.setItem("email", value)
    }
  }

  const [isOptUsed, setIsOptUsed] = useDebugState<boolean>("isOptUsed", false)

  function handleAuthRequest(event: any) {
    event.preventDefault()
    if (!authButtonDisabled) {
      authRequest({ "email": email } as AuthRequest)
        .then(() => setIsOptUsed(true))
    } else {
      console.error("Auth button should be disabled. Set email first")
    }
  }


  const [code, setCode] = useDebugState<string | undefined>("code", undefined)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useDebugState<boolean>("submitButtonDisabled", true)


  function handleSubmitOtp() {
    // const email = localStorage.getItem("email")
    authOtpRequest({ email: email, code: code } as AuthCodeRequest)
      .then((response) => {
        const token = response.token
        if (token) {
          localStorage.setItem("token", token)
          mutateUser && mutateUser()
        } else {
        }
      })
      .catch(e => {
        console.log("AuthOtpView.handleSubmitOtp: Error: " + JSON.stringify(e))
      })
  }

  function handleOtpChange(value: string) {
    setCode(value)
    if (value.length === OTP_LENGTH) {
      setSubmitButtonDisabled(false)
    } else {
      setSubmitButtonDisabled(true)
    }
  }

  // FIXME request name and phone
  return <>
    {disableCondition ? <>
      <Title text="You've logged as" className=""/>
      <TextField_ value={email} disabled={disableCondition} />
    </> : <div style={{
      display: "flex", flexDirection: "column", rowGap: "0.5em", paddingTop: "0.25em", paddingBottom: "1.5em",
    }}>
      <Title text="All right, let’s sign in / sign up" className="order_create_v2__login__title" />
      <Button_ label="Sign in with Google" sx={{ margin: "auto" }} size={"large"} buttonStyle={ButtonStyle.CONTAINED}
               href={getBackendUrl() + "/login?redirect_to=" + window.location.href}
               startIcon={<Google />} />
      {isOptUsed ? <>
        <Text_ content="Enter One-Time Password to sign in" variant="subtitle1" paragraph={true}
               sx={{ textAlign: "center", margin: "auto" }} />
        <OtpInput_ value={code} onChange={handleOtpChange} length={OTP_LENGTH} />
        <Button_ label="Sign In" sx={{ margin: "auto" }} buttonStyle={ButtonStyle.CONTAINED} disabled={submitButtonDisabled} onClickVoid={handleSubmitOtp} />
      </> : <>
        <Text_ content="Enter email to receive a One-Time Password" sx={{ margin: "auto" }} variant="subtitle1" />
        <TextField_ style={{ margin: "auto" }} label="Email" variant="outlined"
                    onChange={e => handleOnEmailChange(e)} value={email} shrink={true}
                    className="login_page__email" />
        <Button_ label="Request One-Time Password" sx={{ margin: "auto" }} size={"large"} buttonStyle={ButtonStyle.CONTAINED}
                 disabled={authButtonDisabled} onClickAny={handleAuthRequest}
                 className="login_page__otp_button" />
      </>}
    </div>}
  </>

}
