import "./style.css"
import { useParams } from "react-router-dom"
import ManagerTab, { ManagerTabConfig } from "../../../../components/tab/ManagerTab/ManagerTab"
import ManagerAgreementCard from "../../../../components/agreement/manager/ManagerAgreementCard/ManagerAgreementCard"

export default function ManagerAgreementCardPage() {
  const { agreementId } = useParams()
  return <div className="manager_agreement_card_page">
    <ManagerTab tabIndex={ManagerTabConfig.Agreements.index}/>
    <ManagerAgreementCard agreementId={agreementId as string}/>
  </div>
}
