import "./style.css"
import { MANAGER__AGREEMENTS__PATH, MANAGER__DASHBOARD__PATH, MANAGER__ORDERS__PATH, MANAGER__STRIPE__PATH } from "../../../config/page_paths"
import Tab_ from "../../elements/Tab/Tab/Tab"

export const ManagerTabConfig = {
  "Dashboard": { "label": "Dashboard", "index": 0 }, "Orders": { "label": "Orders", "index": 1 }, "Stripe": { "label": "Stripe", "index": 2 }, "Agreements": { "label": "Agreements", "index": 3 },
}

interface Props {
  tabIndex: number
}

export default function ManagerTab({ tabIndex }: Props) {
  return <div className="manager_tab">
    <Tab_
      activeIndex={tabIndex}
      tabConfigs={[{ label: ManagerTabConfig.Dashboard.label, navigateTo: MANAGER__DASHBOARD__PATH }, {
        label: ManagerTabConfig.Orders.label, navigateTo: MANAGER__ORDERS__PATH
      }, { label: ManagerTabConfig.Stripe.label, navigateTo: MANAGER__STRIPE__PATH }, { label: ManagerTabConfig.Agreements.label, navigateTo: MANAGER__AGREEMENTS__PATH },]}/>
  </div>
}
