import './style.css'
import UserTab, { UserTabConfig } from '../../../components/tab/UserTab/UserTab'
import UserDashboard from '../../../components/dashboard/UserDashboard/UserDashboard'

export default function UserDashboardPage() {
  return <div className='user_dashboard_page'>
    <UserTab tabIndex={UserTabConfig.Dashboard.index}/>
    <UserDashboard/>
  </div>
}
