import './style.css'
import { useDebugState } from 'use-named-state'
import TextField_ from '../../elements/TextField/TextField'
import Submit_ from '../../elements/Submit/Submit'
import { adminRateCreate } from '../../../api/rate/admin_rate_api'
import { useNavigate } from 'react-router-dom'
import { ADMIN__RATES__PATH } from '../../../config/page_paths'
import AdminRateType from '../AdminRateType/AdminRateType'
import { fromUiRateType } from '../../../utils/rate_type_utils'
import Divider_ from '../../elements/Divider/Divider'
import { AdminRateCreateRequest } from "../../../models/rate/AdminRate"

export default function AdminRateCreate() {
  const navigate = useNavigate()
  const [rateType, setRateType] = useDebugState<string | undefined>('rateType', undefined)
  const [maxDistanceMi, setMaxDistanceMi] = useDebugState<number>('maxDistanceMi', 0)
  const [maxWeightLb, setMaxWeightLb] = useDebugState<number>('maxWeightLb', 0)
  const [maxLengthIn, setMaxLengthIn] = useDebugState<number>('maxLengthIn', 0)
  const [maxWidthIn, setMaxWidthIn] = useDebugState<number>('maxWidthIn', 0)
  const [maxHeightIn, setMaxHeightIn] = useDebugState<number>('maxHeightIn', 0)
  const [value, setValue] = useDebugState<number>('value', 0)

  const [isUploading, setIsUploading] = useDebugState<boolean>('isUploading', false)

  function handleSubmit(event: any) {
    event.preventDefault()
    setIsUploading(true)
    const request = {
      rate_type: fromUiRateType(rateType), max_distance_mi: maxDistanceMi, max_length_in: maxLengthIn, max_width_in: maxWidthIn, max_height_in: maxHeightIn, max_weight_lb: maxWeightLb, value: value,
    } as AdminRateCreateRequest
    adminRateCreate(request)
      .then(rate => navigate(`${ADMIN__RATES__PATH}/${rate.rate_id}`))
      .catch(e => console.log(`AdminRateCreate.handleSubmit.adminRateCreate: ${e}`))
      .finally(() => setIsUploading(false))
  }

  return <div className='admin_rate_create__fields'>
    <Divider_ text='Create new Rate' className='admin_rate_create__divider__rate_overview'/>
    <AdminRateType value={rateType} onChange={setRateType} className='admin_rate_card__rate_type'/>
    <TextField_ label='Max distance, mi' value={maxDistanceMi} onChange={setMaxDistanceMi}/>
    <TextField_ label='Max length, lb' value={maxLengthIn} onChange={setMaxLengthIn}/>
    <TextField_ label='Max width, in' value={maxWidthIn} onChange={setMaxWidthIn}/>
    <TextField_ label='Max height, in' value={maxHeightIn} onChange={setMaxHeightIn}/>
    <TextField_ label='Max weight, lb' value={maxWeightLb} onChange={setMaxWeightLb}/>
    <TextField_ label='Rate, $' value={value} onChange={setValue}/>

    <Submit_ onClick={handleSubmit} isUploading={isUploading}/>
  </div>
}
