import "./style.css"
import ManagerTab, { ManagerTabConfig } from "../../../components/tab/ManagerTab/ManagerTab"
import ManagerDashboard from "../../../components/dashboard/ManagerDashboard/ManagerDashboard"

export default function ManagerDashboardPage() {
  return <div className="manager_dashboard_page">
    <ManagerTab tabIndex={ManagerTabConfig.Dashboard.index}/>
    <ManagerDashboard/>
  </div>
}
