import './style.css'

import { useDebugState } from 'use-named-state'
import { useEffect } from 'react'
import TextField_ from '../../../elements/TextField/TextField'
import { meUpdate } from '../../../../api/user/user_api'
import { useUser } from '../../../../pages/Layout/Layout'
import Tel from '../../../elements/Tel/Tel'
import Button_, { ButtonStyle } from "../../../elements/Button/Button"
import Dialog_ from "../../../elements/Dialog/Dialog"

export default function UserRequestMissingInfo() {
  const { user, mutateUser } = useUser()
  const [name, setName] = useDebugState<string | undefined>('name', undefined)
  const [phoneNumber, setPhoneNumber] = useDebugState<string | undefined>('phoneNumber', undefined)

  useEffect(() => {
    setName(user?.name)
    setPhoneNumber(user?.phone_number)
  }, [user])

  function isChanged() {
    return user?.name !== name || user?.phone_number !== phoneNumber
  }

  function handleClose(): void {
    if (isChanged()) {
      meUpdate({
        name: name, phone_number: phoneNumber,
      }).then(() => {
        mutateUser && mutateUser()
      })
    } else {
      console.log('ExtraInfoDialog.handleClose.isChanged')
    }
  }

  return <>
    <Dialog_
      isOpen={true}
      title="User Profile"
      contentText="Please provide required information:"
      contentTextClassName="user_request_missing_info__message"
      content={<>
        {!user?.name && <TextField_ label='Name' value={name} onChange={setName} className='user_request_missing_info__name'/>}
        <Tel label='Phone number *' value={phoneNumber} onChange={setPhoneNumber} className='user_request_missing_info__phone_number'/>
      </>}
      contentClassName="user_request_missing_info"
      actions={<Button_ buttonStyle={ButtonStyle.CONTAINED} disabled={!isChanged()} onClickVoid={handleClose}>Save</Button_>}
      onClose={handleClose}
    />
  </>
}
