import './style.css'
import { useAdminVisits } from '../../../api/visit/admin_visit_api'
import Table_ from '../../elements/Table/Table'
import { userTimeZoneDate } from '../../../utils/time_utils'
import { CITY_WIDTH, COUNTRY_WIDTH, DATETIME_WIDTH, EMAIL_WIDTH, STATE_WIDTH } from '../../../config/table_witdth_config'

export default function AdminVisitsTable() {
  const { data: visits } = useAdminVisits()
  return <>
    <Table_
      entities={visits}
      config={{
        idKey: 'created_at', columns: [{ header: 'Date', entityPath: ['created_at'], processor: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH }, {
          header: 'IP', entityPath: ['ip'], width: 100
        }, { header: 'Email', entityPath: ['email'], width: EMAIL_WIDTH }, { header: 'Country', entityPath: ['country'], width: COUNTRY_WIDTH }, {
          header: 'State', entityPath: ['state'], width: STATE_WIDTH
        }, { header: 'City', entityPath: ['city'], width: CITY_WIDTH }],
      }}
    />
  </>
}
