import './style.css'
import Divider_ from '../../../elements/Divider/Divider'
import TextField_ from '../../../elements/TextField/TextField'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { BaseRoute } from '../../../../models/route/BaseRoute'

interface Props {
  route: BaseRoute
}

export default function RouteCardRouteInfo({ route }: Props) {
  return <>
    <Divider_ text='Route info' className='route_card__divider__route_info'/>
    <TextField_ label='Start at' value={userTimeZoneDate(route.start_at)} className='route_card__start_at_utc'/>
    <TextField_ label='End at' value={userTimeZoneDate(route.end_at)} className='route_card__end_at_utc'/>
    <TextField_ label='Distance, mi' value={route.distance_data.distance_mi}
                className='route_card__distance_data_distance_mi'/>
    <TextField_ label='Duration, sec' value={route.distance_data.duration_seconds}
                className='route_card__distance_data_duration_seconds'/>
  </>
}
