import "./style.css"
import { useParams } from "react-router-dom"
import ManagerTab, { ManagerTabConfig } from "../../../../components/tab/ManagerTab/ManagerTab"
import ManagerOrderCard from "../../../../components/order/manager/ManagerOrderCard/ManagerOrderCard"

export default function ManagerOrderCardPage() {
  const { orderId } = useParams()
  return <div className="manager_order_card_page">
    <ManagerTab tabIndex={ManagerTabConfig.Orders.index}/>
    <ManagerOrderCard orderId={orderId as string}/>
  </div>
}
