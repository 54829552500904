import './style.css'
import { Button, TableContainer, TablePagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import { useNavigate } from 'react-router-dom'
import TableCell from '@mui/material/TableCell'
import { PageResponse } from '../../../models/Page'
import { packagesToString } from '../../../utils/package_utils'
import Skeleton from '@material-ui/core/Skeleton/Skeleton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@material-ui/core/IconButton'


interface ColumnConfig {
  header: string,
  entityPath: string[]
  width?: number
  processor?: (value: string) => string | undefined
}

interface TableContent {
  idKey: string
  columns: ColumnConfig[]
}

interface Props<T> {
  entities?: T[]
  entitiesPage?: PageResponse<T>
  onPageChange?: (newPage: number) => void
  onPageSizeChange?: (value: any) => void
  config: TableContent
  onRowClickNavigateCommonPath?: string
  onCreateNavigatePath?: string
  rowActionEditByIndex?: (index?: number) => void
  rowActionDeleteByIndex?: (index: number) => void
}

export default function Table_<T>({
                                    entities, entitiesPage, onPageChange, onPageSizeChange, config, onRowClickNavigateCommonPath, onCreateNavigatePath, rowActionEditByIndex, rowActionDeleteByIndex,
                                  }: Props<T>) {

  const navigate = useNavigate()

  const tableEntities = entitiesPage?.content || entities

  const extractValue = (path: string[], element?: any, processor?: (value: string) => string | undefined): string => {
    if (!element) {
      return ''
    }
    if (path.length == 1) {
      if (path[0] === 'packages') {
        return packagesToString(element[path[0]])
      }
      const value = element[path[0]]
      if (processor) {
        return processor(value) || ''
      } else {
        return value
      }
    }
    const newElement = element[path[0]]
    const newPath = path.slice(1)
    return extractValue(newPath, newElement)
  }

  function buildSx(onRowClickNavigateCommonPath?: string) {
    if (onRowClickNavigateCommonPath) {
      return {
        '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer',
      }
    } else {
      return {
        '&:last-child td, &:last-child th': { border: 0 }, cursor: 'auto',
      }
    }
  }

  function buildContent(tableEntities?: T[]) {
    if (tableEntities === undefined) {
      const rowCount = config.columns.length
      return <>
        {Array.from({ length: rowCount }, (_, index) => (<TableCell key={index} component='td' scope='row' sx={{ width: config.columns[index].width }}>
          <Skeleton animation='wave' variant='text'/>
        </TableCell>))}
      </>
    } else if (tableEntities.length > 0) {
      return <>
        {tableEntities.map((element: any, i: number) => (<TableRow key={element[config.idKey]} sx={buildSx(onRowClickNavigateCommonPath)} hover
                                                                   onClick={() => onRowClickNavigateCommonPath && navigate(onRowClickNavigateCommonPath + element[config.idKey])}>
          {config.columns.map((c, j) => <TableCell key={j} sx={{ width: c.width }} className='elements_table__table_cell__body'>{extractValue(c.entityPath, element, c.processor)}</TableCell>)}
          {(rowActionEditByIndex || rowActionDeleteByIndex) && <>
            <TableCell key={config.columns.length}>
              {rowActionEditByIndex && <IconButton onClick={() => rowActionEditByIndex(i)}>
                <EditIcon fontSize='small'/>
              </IconButton>}
              {rowActionDeleteByIndex && <IconButton onClick={() => rowActionDeleteByIndex(i)}>
                <DeleteIcon fontSize='small'/>
              </IconButton>}
            </TableCell>
          </>}
        </TableRow>))}
      </>
    } else {
      return <></>
    }
  }

  return <>
    <Paper className='elements_table__container'>
      <TableContainer>
        <Table className='elements_table__table'>
          <TableHead>
            <TableRow>
              {config.columns.map((column, i) => <>
                <TableCell key={i} width={column.width} className='elements_table__table_cell__head'>{column.header}</TableCell>
              </>)}
              {(rowActionEditByIndex || rowActionDeleteByIndex) && <TableCell key={config.columns.length} className='elements_table__table_cell__head'>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {buildContent(tableEntities)}
          </TableBody>
        </Table>
      </TableContainer>
      {entitiesPage && onPageChange && onPageSizeChange && <TablePagination
        component='div'
        count={entitiesPage.total_pages * entitiesPage.page_size}
        labelRowsPerPage={<span>Rows:</span>}
        rowsPerPageOptions={[5, 10, 25, 100]}
        rowsPerPage={entitiesPage.page_size}
        onRowsPerPageChange={(event: any) => {
          const newPageSize = parseInt(event.target.value, 10)
          onPageSizeChange(newPageSize)
          onPageChange(0)
        }}
        labelDisplayedRows={({ page }) => `Page: ${page}`}
        page={entitiesPage.page}
        onPageChange={(_: any, newPage: number) => onPageChange(newPage)}
        showFirstButton={true}
        showLastButton={true}
      />}
    </Paper>
    {onCreateNavigatePath && <div className='elements_table__add_new'>
      <Button size='medium' onClick={() => navigate(onCreateNavigatePath)} variant='contained'>Add new</Button>
    </div>}
  </>
}
