import './style.css'
import Email from '../../../elements/Email/Email'
import Tel from '../../../elements/./Tel/Tel'
import TextField_ from '../../../elements/TextField/TextField'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import Divider_ from '../../../elements/Divider/Divider'

interface Props {
  order: BaseOrder
}

export default function OrderCardContactInfo({ order }: Props) {
  return <>
    <Divider_ text='Contact info' className='order_card__divider__contact_info'/>
    <Email label='Email' value={order.email} className='order_card__email'/>
    <Tel label='Phone number' value={order.phone_number} className='order_card__phone_number'/>
    <TextField_ label='Comment' value={order.comment} className='order_card__comment'/>
  </>
}
