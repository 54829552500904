import { useDebugState } from "use-named-state"
import Table_ from "../../../elements/Table/Table"
import { AGREEMENT_EFFECTIVE_DATE_WIDTH, AGREEMENT_ID_WIDTH, AGREEMENT_NAME_WIDTH } from "../../../../config/table_witdth_config"
import { userTimeZoneDate } from "../../../../utils/time_utils"
import { useManagerAgreementsActive } from "../../../../api/agreement/manager_agreement_api"

export default function ManagerAgreementsTable() {
  const [page, setPage] = useDebugState("page", 0)
  const [pageSize, setPageSize] = useDebugState("pageSize", 10)
  const { data: usersPage } = useManagerAgreementsActive(page, pageSize)
  return <>
    <Table_
      entitiesPage={usersPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath={"/manager/agreements/"}
      config={{
        idKey: "agreement_id", columns: [{ header: "Name", entityPath: ["name"], width: AGREEMENT_NAME_WIDTH }, { header: "Number", entityPath: ["number"], width: AGREEMENT_EFFECTIVE_DATE_WIDTH }, {
          header: "Effective Date", entityPath: ["effective_date"], processor: (value: string) => userTimeZoneDate(value), width: AGREEMENT_EFFECTIVE_DATE_WIDTH
        }, { header: "Agreement ID", entityPath: ["agreement_id"], width: AGREEMENT_ID_WIDTH },],
      }}
    />
  </>
}
