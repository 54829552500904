import "./style.css"
import AdminTab, { AdminTabConfig } from "../../../../components/tab/AdminTab/AdminTab"
import AdminOrdersTable from "../../../../components/order/admin/AdminOrdersTable/AdminOrdersTable"

export default function AdminOrdersPage() {
  return <div className="admin_orders_page">
    <AdminTab tabIndex={AdminTabConfig.Orders.index}/>
    <AdminOrdersTable/>
  </div>
}
