interface Props {
  text?: string
  href?: string
  tel?: string
  mailTo?: string
  className?: string
}

export default function A({ text, href, tel, mailTo, className }: Props) {
  function buildHref() {
    if (tel) {
      return `tel:${tel}`
    } else if (mailTo) {
      return `mailto:${mailTo}`
    } else {
      return href
    }
  }

  return <>
    <a href={buildHref()} className={className}>{text}</a>
  </>
}
