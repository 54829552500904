import './style.css'
import H from '../../components/elements/H/H'

interface Props {
  message?: string
}

export default function NotFoundPage({ message }: Props) {
  return <div className='not_found_page'>
    <H variant='6' text={message || 'Page not found'} className='not_found_page__title'/>
  </div>
}
