import "./style.css"
import { useNavigate } from "react-router-dom"
import { LOGIN_WITH_OTP__PATH } from "../../../config/page_paths"
import { getBackendUrl, redirectToUrl } from "../../../config/env"
import { useDebugState } from "use-named-state"
import { AuthRequest } from "../../../models/auth/Auth"
import { authRequest } from "../../../api/auth/auth_api"
import Button_, { ButtonStyle } from "../../../components/elements/Button/Button"
import Divider_ from "../../../components/elements/Divider/Divider"
import Text_ from "../../../components/elements/Text/Text"
import TextField_ from "../../../components/elements/TextField/TextField"
import GoogleIcon_ from "../../../components/elements/icons/GoogleIcon"

export default function LoginPage() {
  const navigate = useNavigate()

  const [email, setEmail] = useDebugState<string | undefined>("email", undefined)
  const [authButtonDisabled, setAuthButtonDisabled] = useDebugState<boolean>("authButtonDisabled", true)

  function handleOnEmailChange(value: string): void {
    setEmail(value)
    if (value !== "") {
      setAuthButtonDisabled(false)
      localStorage.setItem("email", value)
    } else {
      setAuthButtonDisabled(true)
      localStorage.setItem("email", value)
    }
  }

  function handleAuthRequest(event: any): void {
    event.preventDefault()
    if (!authButtonDisabled) {
      authRequest({ "email": email } as AuthRequest)
        .then((_) => navigate(LOGIN_WITH_OTP__PATH))
    } else {
      console.log("Auth button should be disabled. Set email first")
    }
  }

  return <div className="login_page">
    <Button_ label="Sign in with Google" size={"large"} buttonStyle={ButtonStyle.CONTAINED}
             href={getBackendUrl() + "/login?redirect_to=" + redirectToUrl()} startIcon={<GoogleIcon_/>}/>
    <Divider_ text="OR" sx={{ width: "100%" }} flexItem={true} className="login_page__divider"/>
    <Text_ content="Enter email to receive a One-Time Password" variant="subtitle1"/>
    <TextField_ label="Email" variant="outlined"
                onChange={handleOnEmailChange}
                value={email}
                shrink={true} className="login_page__email"/>
    <Button_ size="large" buttonStyle={ButtonStyle.CONTAINED} disabled={authButtonDisabled}
             onClickAny={handleAuthRequest} className="login_page__otp_button">Request One-Time Password</Button_>
  </div>
}
