import { TextField, TextFieldVariants } from "@mui/material"
import { CSSProperties } from "react"


export type TextSize = "small" | "medium"

interface Props {
  label?: string,
  disabled?: boolean
  value?: any
  onChange?: (value: any) => void
  valid?: boolean
  variant?: TextFieldVariants
  helperText?: string
  shrink?: boolean
  textSize?: TextSize
  className?: string
  style?: CSSProperties
}

export default function TextField_({
                                     label, disabled, value, onChange, valid, variant, helperText, shrink, textSize, className, style
                                   }: Props) {
  return <div className={className} style={style}>
    <TextField
      size={textSize}
      error={valid !== undefined ? !valid : false}
      sx={{
        // "& input:disabled": { textFillColor: "black" },
        // "& MuiInputBase-input-MuiOutlinedInput-input": {
        "& .MuiInputBase-input": {
          height: "19px"
        }
      }}
      fullWidth
      disabled={onChange === undefined || disabled}
      label={label}
      variant={variant ? variant : (onChange ? "outlined" : "filled")}
      value={value ? value.toString() : undefined}
      InputLabelProps={{ shrink: shrink !== undefined ? shrink : true }}
      helperText={helperText}
      onChange={onChange ? (event) => onChange(event.target.value) : undefined}
    />
  </div>
}
