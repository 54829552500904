import './style.css'
import { useDebugState } from 'use-named-state'
import { OrderStatus } from '../../../../models/order/OrderStatus'
import Button_ from '../../../elements/Button/Button'
import { driverOrderAssign, driverOrderDeliver, driverOrderPickup } from '../../../../api/order/driver_order_api'
import FileInput, { FileInputType } from '../../../elements/./FileInput/FileInput'
import TextField_ from '../../../elements/TextField/TextField'
import { DriverOrder } from '../../../../models/order/DriverOrder'
import Submit_ from '../../../elements/Submit/Submit'
import Text_ from "../../../elements/Text/Text"

interface Props {
  order: DriverOrder
  mutateOrder?: () => void
  orderId: string
  orderStatus: OrderStatus
}

export function DriverOrderActions({ order, mutateOrder, orderId, orderStatus }: Props) {
  const [files, setFiles] = useDebugState<FileList | undefined>('files', undefined)
  const [internalComment, setInternalComment] = useDebugState<string | undefined>('internalComment', undefined)

  function cleanUp() {
    setFiles(undefined)
    setInternalComment(undefined)
  }

  function buildActions(orderStatus: OrderStatus) {
    if (orderStatus == OrderStatus.PAYMENT_HOLD_SUCCESS) {
      return <>
        <Submit_ label='Assign to me' onClick={() => driverOrderAssign(orderId, window.location.href)}/>
      </>
    } else if (orderStatus == OrderStatus.ASSIGNED) {
      if (order.ready_for_pickup) {
        let lambda: (() => Promise<any>) | undefined = undefined
        if (files) {
          lambda = () => driverOrderPickup(orderId, { internal_comment: internalComment }, files)
            .then(() => {
              cleanUp()
              mutateOrder && mutateOrder()
            })
        }
        return <>
          <Text_ content="Attach pickup photos:"/>
          <FileInput label='Upload Photos' type={FileInputType.IMAGE} files={files} onChange={setFiles}/>
          <TextField_ label='Internal comment' value={internalComment} onChange={setInternalComment}/>
          <Button_ label='Pick up an Order' disabled={!files} onClick={lambda}
                   logPath='DriverOrderActions.buildActions.driverOrderPickup'/>
        </>
      } else {
        return <Text_ content={`Proceed to the Pickup location: ${order.pickup_from.full_address}`}/>
      }
    } else if (orderStatus == OrderStatus.PICKED_UP) {
      if (order.ready_for_delivery) {
        let lambda: (() => Promise<any>) | undefined = undefined
        if (files) {
          lambda = () => driverOrderDeliver(orderId, { internal_comment: internalComment }, files)
            .then(() => {
              cleanUp()
              mutateOrder && mutateOrder()
            })
        }
        return <>
          <Text_ content="Attach delivery photos:"/>
          <FileInput label='Upload Photos' type={FileInputType.IMAGE} files={files} onChange={setFiles}/>
          <TextField_ label='Internal comment' value={internalComment} onChange={setInternalComment}/>
          <Button_ label='Deliver an Order' disabled={!files} onClick={lambda}
                   logPath='DriverOrderActions.buildActions.driverOrderDeliver'/>
        </>
      } else {
        return <Text_ content={`Proceed to the Delivery location: ${order.delivery_to.full_address}`}/>
      }
    } else {
      return <>
      </>
    }
  }

  return <div className='driver_order_actions'>
    {buildActions(orderStatus)}
  </div>
}
