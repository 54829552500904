import useSWR, { SWRResponse } from 'swr'
import { PageRequest, PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'
import { BaseAgreement } from '../../models/agreement/BaseAgreement'
import { http } from "../http"

export function useUserAgreementActive(agreementId: string): SWRResponse<BaseAgreement> {
  return useSWR<BaseAgreement>([`/agreements/${agreementId}/active`])
}

export function getUserAgreementsActive(page?: number, pageSize?: number) {
  return http.postData<PageRequest<any>, PageResponse<BaseAgreement>>("/agreements/search-active", {
    page: page, page_size: pageSize,
  })
}

export function useUserAgreementsActive(page?: number, pageSize?: number): SWRResponse<PageResponse<BaseAgreement>> {
  return usePageable(
    '/agreements/search-active',
    {
      page: page,
      page_size: pageSize,
    } as PageRequest<any>
  )
}
