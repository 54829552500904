import './style.css'
import TextField_ from '../../../elements/TextField/TextField'
import Divider_ from '../../../elements/Divider/Divider'
import { AdminAgreement } from '../../../../models/agreement/AdminAgreement'

interface Props {
  agreement: AdminAgreement
}

export default function AgreementCardStatusInfo({ agreement }: Props) {
  return <>
    <Divider_ text='Staus info' className='agreement_card__divider__status_info'/>
    <TextField_ label='Status' value={agreement.status} className='agreement_card__status'/>
    <TextField_ label='User Role' value={agreement.user_role} className='agreement_card__user_role'/>
  </>
}
