import './style.css'
import { useParams } from 'react-router-dom'
import DriverRouteCard from '../../../components/route/driver/DriverRouteCard/DriverRouteCard'
import DriverTab, { DriverTabConfig } from '../../../components/tab/DriverTab/DriverTab'

export default function DriverRouteCardPage() {
  const { routeId } = useParams()
  return <div className='driver_route_card_page'>
    <DriverTab tabIndex={DriverTabConfig.Routes.index}/>
    <DriverRouteCard routeId={routeId as string}/>
  </div>
}
