export const DATETIME_WIDTH = 215
export const ID_WIDTH = 120
export const DISTANCE_MI_WIDTH = 120
export const DRIVER_DISTANCE_MI_WIDTH = 150
export const DISTANCE_EXTRA_MI_WIDTH = 160
export const EMAIL_WIDTH = 200
export const COUNTRY_WIDTH = 200
export const STATE_WIDTH = 160
export const CITY_WIDTH = 180
export const USER_NAME_WIDTH = 180
export const RATE_WIDTH = 120
export const PHONE_NUMBER_WIDTH = 150
export const DRIVER_PHONE_NUMBER_WIDTH = 180
export const SHORT_ADDRESS_WIDTH = 200
export const USER_ROLE_WIDTH = 100
export const USER_DRIVER_STATUS_WIDTH = 200
export const ORDER_STATUS_WIDTH = 150
export const AGREEMENT_ID_WIDTH = 170
export const AGREEMENT_STATUS_WIDTH = 150
export const AGREEMENT_NAME_WIDTH = 180
export const AGREEMENT_EFFECTIVE_DATE_WIDTH = 215
