export enum OrderStatus {
  NEW__ADDRESS_AND_PACKAGE = 'NEW__ADDRESS_AND_PACKAGE',
  NEW__DETAILS_AND_PHOTOS = 'NEW__DETAILS_AND_PHOTOS',
  PAYMENT_SETUP_REQUESTED = 'PAYMENT_SETUP_REQUESTED',
  PAYMENT_SETUP_FAILED = 'PAYMENT_SETUP_FAILED',
  PAYMENT_SETUP_SUCCESS = 'PAYMENT_SETUP_SUCCESS',
  PAYMENT_HOLD_REQUESTED = 'PAYMENT_HOLD_REQUESTED',
  PAYMENT_HOLD_FAILED = 'PAYMENT_HOLD_FAILED',
  PAYMENT_HOLD_SUCCESS = 'PAYMENT_HOLD_SUCCESS',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  ASSIGNED = 'ASSIGNED',
  PICKED_UP = 'PICKED_UP',
  DELIVERED = 'DELIVERED',
  PAYMENT_CAPTURE_REQUESTED = 'PAYMENT_CAPTURE_REQUESTED',
  PAYMENT_CAPTURE_FAILED = 'PAYMENT_CAPTURE_FAILED',
  PAYMENT_CAPTURE_SUCCESS = 'PAYMENT_CAPTURE_SUCCESS',
}
