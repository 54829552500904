import "./style.css"
import ManagerTab, { ManagerTabConfig } from "../../../../components/tab/ManagerTab/ManagerTab"
import ManagerOrdersTable from "../../../../components/order/manager/ManagerOrdersTable/ManagerOrdersTable"

export default function ManagerOrdersPage() {
  return <div className="manager_orders_page">
    <ManagerTab tabIndex={ManagerTabConfig.Orders.index}/>
    <ManagerOrdersTable/>
  </div>
}
