import './style.css'
import { Document, Page, pdfjs } from 'react-pdf'
import { useState } from 'react'
import Button_ from "../../elements/Button/Button"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  pdfUrl?: string
}

export default function PDFViewer({ pdfUrl }: Props) {
  const [showPdf, setShowPdf] = useState<boolean>(false)
  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [renderNavButtons, setRenderNavButtons] = useState<boolean>(false)

  function previousPage() {
    setPageNumber(prevPageNumber => prevPageNumber - 1)
  }

  function nextPage() {
    setPageNumber(prevPageNumber => prevPageNumber + 1)
  }

  function navigationButtons(className: string) {
    return <div className={className}>
      <Button_ label="Previous Page" disabled={pageNumber <= 1} onClickVoid={previousPage}/>
      <Button_ label="Next Page" disabled={pageNumber === numPages} onClickVoid={nextPage}/>
    </div>
  }

  return <div className='pdf_viewer'>
    {!showPdf ? <Button_ label="Show PDF" onClickVoid={() => setShowPdf(true)}/> : <Button_ label="Hide PDF" onClickVoid={() => setShowPdf(false)}/>}
    {showPdf && <>
      {renderNavButtons && navigationButtons('pdf_viewer__top_navigation')}
      <div>
        <Container className='pdf_container' maxWidth='md'>
          <Paper elevation={3} className='pdf_document'>
            <Document
              file={pdfUrl}
              onLoadSuccess={({ numPages }) => {
                setNumPages(numPages)
                setRenderNavButtons(true)
              }}
              onError={e => console.log(e)}
            >
              <Page pageNumber={pageNumber} className='pdf_page'/>
            </Document>
          </Paper>
        </Container>
      </div>
      {renderNavButtons && navigationButtons('pdf_viewer__bottom_navigation')}
    </>}
  </div>
}
