import './style.css'
import UserOrdersTable from '../../order/user/UserOrdersTable/UserOrdersTable'
import { useUserOrderCurrent } from '../../../api/order/user_order_api'
import Map_ from '../../elements/Map/Map'
import OrderShortDetailsWithTimeline from '../../order/OrderShortDetailsWithTimeline/OrderShortDetailsWithTimeline'
import Divider_ from '../../elements/Divider/Divider'
import { buildMarkers, buildPolylines } from '../../elements/Map/utils'

export default function UserDashboard() {
  const { data: currentOrder, mutate: mutateOrder } = useUserOrderCurrent()

  return <div className='user_dashboard__fields'>
    {currentOrder && <>
      <Divider_ text='Current Order' className='user_dashboard__divider__current_order'/>
      <div className='user_dashboard__order_short_details_with_timeline'>
        <OrderShortDetailsWithTimeline orderId={currentOrder.order_id} orderStatus={currentOrder.status}
                                       userRate={currentOrder.user_rate} mutateOrder={mutateOrder}/>
      </div>

      <Map_ polylines={buildPolylines(currentOrder)} markers={buildMarkers([currentOrder])}
            className='user_dashboard__map'/>
    </>}
    <Divider_ text='My Orders' className='user_dashboard__divider__orders'/>
    <UserOrdersTable/>
  </div>
}
