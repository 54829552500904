import SearchIcon from "@mui/icons-material/Search"

type Color = "disabled" | "action" | "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning"

interface Props {
  color: Color
}

export default function SearchIcon_({ color }: Props) {
  return <SearchIcon color={color} fontSize="large"/>
}
