import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { AddressRequest } from '../../../models/Address'
import { CSSProperties } from "react"
import { LoaderOptions } from "@googlemaps/js-api-loader"

const US_REGION_GOOGLE_PLACES_REQUEST_OPTIONS = { 'region': 'us' }

interface Props {
  placesOptions?: Partial<LoaderOptions>
  placeholder: string
  className?: string
  onChange: (address: AddressRequest) => void
  style?: CSSProperties
}

export default function AddressAutocomplete({ placesOptions, placeholder, className, onChange, style }: Props) {
  const toGooglePlacesResponse = (value: any) => {
    const splitAddress = value.value.terms.map((term: any) => term.value).reverse()
    return {
      full_address: value.label, building: splitAddress[4], street: splitAddress[3], city: splitAddress[2], state: splitAddress[1], country: splitAddress[0]
    } as AddressRequest
  }

  return <div className={className} style={style}>
    <GooglePlacesAutocomplete
      autocompletionRequest={{ componentRestrictions: { country: ['us'] } }}
      apiOptions={placesOptions ? placesOptions : US_REGION_GOOGLE_PLACES_REQUEST_OPTIONS}
      selectProps={{
        onChange: (newValue: any) => onChange(toGooglePlacesResponse(newValue)), placeholder: placeholder, styles: { input: (provided) => ({ ...provided, height: '2.5em' }) },
      }}
    />
  </div>
}
