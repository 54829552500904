import "./style.css"
import DriverTab, { DriverTabConfig } from "../../../components/tab/DriverTab/DriverTab"
import Text_ from "../../../components/elements/Text/Text"

export default function DriverStripePage() {
  return <div className='driver_stripe_page'>
    <DriverTab tabIndex={DriverTabConfig.Stripe.index}/>
    <Text_ className='driver_stripe_page__description'>
      Login to <a href='https://connect.stripe.com/express_login' target='_blank'>Stripe</a> account to get more details
    </Text_>
  </div>
}
