import IconButton from "@mui/material/IconButton"
import { ReactNode } from "react"

interface Props {
  onClick: () => void
  children: ReactNode
  className?: string
}

export default function IconButton_({ onClick, children, className }: Props) {
  return <>
    <IconButton onClick={onClick} className={className}>
      {children}
    </IconButton>
  </>
}
