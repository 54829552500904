export enum PageState {
  ShowAddress, // 0 - "No order",
  ShowPackage, // 1 - "No order; filled address",
  ShowPickupAndDeliveryDetails, // 2 - "order.NEW__ADDRESS_AND_PACKAGE",
  ShowPhotosDecision, // 3 - "order.NEW__ADDRESS_AND_PACKAGE; y/n photos",
  ShowPhotosUploadAndProceed, // 4 - "order.NEW__ADDRESS_AND_PACKAGE; y/n photos; uploading",
  ShowLogin, // 5 - "order.NEW__DETAILS_AND_PHOTOS; !order.user_id",
  ShowPaymentSetup, // 6 - "order.NEW__DETAILS_AND_PHOTOS; order.user_id; !order.user_has_payment_setup",
  ShowPaymentSetupRequested, // 7 - "order.PAYMENT_SETUP_REQUESTED",
  ShowPaymentSetupFailed, // 8 - "order.PAYMENT_SETUP_FAILED",
  ShowConfirmAndAgreement, // 9 - 'order.PAYMENT_SETUP_SUCCESS',
  ShowConfirmAndAgreementRequested, // 10 - 'order.PAYMENT_HOLD_REQUESTED',
  ShowConfirmAndAgreementFailed, // 11 - 'order.PAYMENT_HOLD_FAILED',
  ShowDone, // 12 - 'order.PAYMENT_HOLD_SUCCESS',
}
