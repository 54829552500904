import "./style.css"
import { UserDriverStatus } from "../../../../models/user/UserDriverStatus"
import SelectField_ from "../../../elements/SelectField/SelectField"
import { BaseUser } from "../../../../models/user/BaseUser"
import { UserRole } from "../../../../models/user/UserRole"
import { toUiUserRole } from "../../../../utils/user_role_utils"

export const USER_SELECTED_ROLE_VALUE = "- Empty -"


interface Props {
  user: BaseUser
  value?: string
  onChange: (value: any) => void
  className?: string
}

export default function UserCardSelectedRole({ user, value, onChange, className }: Props) {

  function buildValues() {
    const values: string[] = [USER_SELECTED_ROLE_VALUE]
    if (user.role === UserRole.DRIVER) {
      values.push(toUiUserRole(UserRole.USER)!!)
      values.push(toUiUserRole(UserRole.DRIVER)!!)
    } else if (user.role === UserRole.ADMIN) {
      if (user.driver_status === UserDriverStatus.ACCEPTED) {
        values.push(toUiUserRole(UserRole.USER)!!)
        values.push(toUiUserRole(UserRole.DRIVER)!!)
        values.push(toUiUserRole(UserRole.MANAGER)!!)
        values.push(toUiUserRole(UserRole.ADMIN)!!)
      } else {
        values.push(toUiUserRole(UserRole.USER)!!)
        values.push(toUiUserRole(UserRole.MANAGER)!!)
        values.push(toUiUserRole(UserRole.ADMIN)!!)
      }
    } else if (user.role === UserRole.MANAGER) {
      if (user.driver_status === UserDriverStatus.ACCEPTED) {
        values.push(toUiUserRole(UserRole.USER)!!)
        values.push(toUiUserRole(UserRole.DRIVER)!!)
        values.push(toUiUserRole(UserRole.ADMIN)!!)
      } else {
        values.push(toUiUserRole(UserRole.USER)!!)
        values.push(toUiUserRole(UserRole.ADMIN)!!)
      }
    }
    return values
  }

  return <>
    <SelectField_
      label="User selected Role"
      value={value}
      values={buildValues()}
      onChange={(newValue) => onChange(newValue !== USER_SELECTED_ROLE_VALUE ? newValue : undefined)}
      className={className}
    />
  </>
}
