import "./style.css"
import { useParams } from "react-router-dom"
import DriverOrderCard from "../../../../components/order/driver/DriverOrderCard/DriverOrderCard"
import DriverTab, { DriverTabConfig } from "../../../../components/tab/DriverTab/DriverTab"

export default function DriverOrderCardPage() {
  const { orderId } = useParams()
  return <div className="driver_order_card_page">
    <DriverTab tabIndex={DriverTabConfig.Orders.index}/>
    <DriverOrderCard orderId={orderId as string}/>
  </div>
}
