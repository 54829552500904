import Divider from '@mui/material/Divider'
import { SxProps } from "@mui/system"

interface Props {
  text?: string
  flexItem?: boolean
  sx?: SxProps
  className?: string
}

export default function Divider_({ text, flexItem, sx, className }: Props) {
  return <>
    <Divider flexItem={flexItem} sx={sx} className={className}>
      {text && <b>{text}</b>}
    </Divider>
  </>
}
