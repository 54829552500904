import './style.css'
import TextField_ from '../../elements/TextField/TextField'
import { adminRateDelete, adminRateUpdate, useAdminRate } from '../../../api/rate/admin_rate_api'
import { userTimeZoneDate } from '../../../utils/time_utils'
import { useDebugState } from 'use-named-state'
import { useEffect } from 'react'
import { AdminRateUpdateRequest } from '../../../models/rate/AdminRate'
import Button_, { ButtonStyle } from '../../elements/Button/Button'
import { ADMIN__RATES__PATH } from '../../../config/page_paths'
import Divider_ from '../../elements/Divider/Divider'
import { fromUiRateType, toUiRateType } from '../../../utils/rate_type_utils'
import AdminRateType from "../AdminRateType/AdminRateType"
import CircularProgress_ from "../../elements/CircularProgress/CircularProgress_"

interface Props {
  rateId: string
}

export default function AdminRateCard({ rateId }: Props) {
  const { data: rate, mutate: mutateRate } = useAdminRate(rateId)
  const [rateType, setRateType] = useDebugState<string | undefined>('rateType', undefined)
  const [maxDistanceMi, setMaxDistanceMi] = useDebugState<number | undefined>('maxDistanceMi', undefined)
  const [maxWeightLb, setMaxWeightLb] = useDebugState<number | undefined>('maxWeightLb', undefined)
  const [maxLengthIn, setMaxLengthIn] = useDebugState<number | undefined>('maxLengthIn', undefined)
  const [maxWidthIn, setMaxWidthIn] = useDebugState<number | undefined>('maxWidthIn', undefined)
  const [maxHeightIn, setMaxHeightIn] = useDebugState<number | undefined>('maxHeightIn', undefined)
  const [value, setValue] = useDebugState<number | undefined>('value', undefined)

  function isChanged() {
    if (rate) {
      return toUiRateType(rate?.rate_type) !== rateType || (rate && +rate?.max_distance_mi) !== (maxDistanceMi && +maxDistanceMi) || (rate && rate?.max_weight_lb) !== (maxWeightLb && +maxWeightLb) || (rate && +rate?.max_length_in) !== (maxLengthIn && +maxLengthIn) || (rate && +rate?.max_width_in) !== (maxWidthIn && +maxWidthIn) || (rate && +rate?.max_height_in) !== (maxHeightIn && +maxHeightIn) || (rate && +rate?.value) !== (value && +value)
    }
  }

  useEffect(() => {
    if (rate) {
      setRateType(toUiRateType(rate.rate_type))
      setMaxDistanceMi(rate.max_distance_mi)
      setMaxWeightLb(rate.max_weight_lb)
      setMaxLengthIn(rate.max_length_in)
      setMaxWidthIn(rate.max_width_in)
      setMaxHeightIn(rate.max_height_in)
      setValue(rate.value)
    }
  }, [rate])

  function handleSaveClick() {
    const request = {
      rate_type: fromUiRateType(rateType), max_distance_mi: maxDistanceMi, max_weight_lb: maxWeightLb, max_length_in: maxLengthIn, max_width_in: maxWidthIn, max_height_in: maxHeightIn, value: value,
    } as AdminRateUpdateRequest
    return adminRateUpdate(rateId, request)
      .then(_ => mutateRate())
  }

  return <>
    {rate ? <div className='admin_rate_card__fields'>
      <Divider_ text={`rate overview: ${rateId}`} className='admin_rate_card__divider__rate_overview'/>
      <TextField_ label='Rate, $' value={value} onChange={setValue} className='admin_rate_card__value'/>
      <AdminRateType value={rateType} onChange={setRateType} className='admin_rate_card__rate_type'/>
      <TextField_ label='Max distance, mi' value={maxDistanceMi} onChange={setMaxDistanceMi}
                  className='admin_rate_card__max_distance_mi'/>
      <TextField_ label='Max length, in' value={maxLengthIn} onChange={setMaxLengthIn}
                  className='admin_rate_card__max_length_in'/>
      <TextField_ label='Max width, in' value={maxWidthIn} onChange={setMaxWidthIn}
                  className='admin_rate_card__max_width_in'/>
      <TextField_ label='Max height, in' value={maxHeightIn} onChange={setMaxHeightIn}
                  className='admin_rate_card__max_height_in'/>
      <TextField_ label='Max weight, lb' value={maxWeightLb} onChange={setMaxWeightLb}
                  className='admin_rate_card__max_weight_lb'/>

      <Divider_ text='Basic info' className='admin_rate_card__divider__basic_info'/>
      <TextField_ label='Rate ID' value={rate.rate_id} className='admin_rate_card__rate_id'/>
      <TextField_ label='Create at' value={userTimeZoneDate(rate.created_at)} className='admin_rate_card__created_at'/>
      <TextField_ label='Update at' value={userTimeZoneDate(rate.updated_at)} className='admin_rate_card__updated_at'/>

      <Button_ label='Save' disabled={!isChanged()} onClick={handleSaveClick}
               logPath='AdminrateCard.handleSaveClick.adminrateUpdate' className='admin_rate_card__save'/>
      <Button_ label='Delete' buttonStyle={ButtonStyle.RED} onClick={() => adminRateDelete(rateId)}
               navigateTo={ADMIN__RATES__PATH} logPath='AdminrateCard.adminrateDelete'
               className='admin_rate_card__delete'/>
    </div> : <CircularProgress_/>}
  </>
}
