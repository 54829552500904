import './style.css'
import { useParams } from 'react-router-dom'
import AdminRouteCard from '../../../components/route/admin/AdminRouteCard/AdminRouteCard'
import AdminTab, { AdminTabConfig } from '../../../components/tab/AdminTab/AdminTab'

export default function AdminRouteCardPage() {
  const { routeId } = useParams()
  return <div className='admin_route_card_page'>
    <AdminTab tabIndex={AdminTabConfig.Routes.index}/>
    <AdminRouteCard routeId={routeId as string}/>
  </div>
}
