import { useDebugState } from "use-named-state"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { ReactNode } from "react"

interface Props {
  title: string
  isOpen: boolean
  contentText: string
  contentTextClassName?: string
  contentClassName?: string
  content?: ReactNode
  actions: ReactNode
  onClose?: () => void
}

export default function Dialog_({ isOpen, title, contentText, content, actions, contentClassName, contentTextClassName, onClose }: Props) {
  const [open, setOpen] = useDebugState('open', isOpen)

  function handleClose() {
    onClose && onClose()
    setOpen(false)
  }

  return <>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={contentClassName}>
        <DialogContentText className={contentTextClassName}>{contentText}</DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  </>
}