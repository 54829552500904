import { UserRole } from '../models/user/UserRole'

const ADMIN_ROLE = 'Admin'
const DRIVER_ROLE = 'Driver'
const USER_ROLE = 'User'
const MANAGER_ROLE = 'Manager'


export function toUiUserRole(userRole?: UserRole): string | undefined {
  switch (userRole) {
    case UserRole.ADMIN:
      return ADMIN_ROLE
    case UserRole.DRIVER:
      return DRIVER_ROLE
    case UserRole.USER:
      return USER_ROLE
    case UserRole.MANAGER:
      return MANAGER_ROLE
    case undefined:
      return undefined
  }
}

export function fromUiUserRole(uiUserRole?: string): UserRole | undefined {
  switch (uiUserRole) {
    case ADMIN_ROLE:
      return UserRole.ADMIN
    case DRIVER_ROLE:
      return UserRole.DRIVER
    case USER_ROLE:
      return UserRole.USER
    case MANAGER_ROLE:
      return UserRole.MANAGER
    case undefined:
      return undefined
  }
}
