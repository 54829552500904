import './style.css'
import { useAdminUsers } from '../../../../api/user/admin_user_api'
import Table_ from '../../../elements/Table/Table'
import { useDebugState } from 'use-named-state'
import { toUiUserRole } from '../../../../utils/user_role_utils'
import { UserRole } from '../../../../models/user/UserRole'
import { toUiUserDriverStatus } from '../../../../utils/user_driver_status_utils'
import { UserDriverStatus } from '../../../../models/user/UserDriverStatus'
import { EMAIL_WIDTH, ID_WIDTH, PHONE_NUMBER_WIDTH, USER_DRIVER_STATUS_WIDTH, USER_NAME_WIDTH, USER_ROLE_WIDTH } from '../../../../config/table_witdth_config'

export default function AdminUsersTable() {
  const [page, setPage] = useDebugState('page', 0)
  const [pageSize, setPageSize] = useDebugState('pageSize', 10)
  const { data: usersPage } = useAdminUsers(page, pageSize)
  return <>
    <Table_
      entitiesPage={usersPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath={'/admin/users/'}
      config={{
        idKey: 'user_id',
        columns: [{ header: 'Email', entityPath: ['email'], width: EMAIL_WIDTH }, {
          header: 'Role',
          entityPath: ['role'],
          processor: (value: string) => toUiUserRole(value as UserRole),
          width: USER_ROLE_WIDTH
        }, { header: 'Name', entityPath: ['name'], width: USER_NAME_WIDTH }, { header: 'Phone number', entityPath: ['phone_number'], width: PHONE_NUMBER_WIDTH }, {
          header: 'Driver status',
          entityPath: ['driver_status'],
          processor: (value: string) => toUiUserDriverStatus(value as UserDriverStatus),
          width: USER_DRIVER_STATUS_WIDTH
        }, { header: 'User ID', entityPath: ['user_id'], width: ID_WIDTH },],
      }}
    />
  </>
}
