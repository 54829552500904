import './style.css'
import AdminUsersTable from '../../../components/user/admin/AdminUsersTable/AdminUsersTable'
import AdminTab, { AdminTabConfig } from '../../../components/tab/AdminTab/AdminTab'

export default function AdminUsersPage() {
  return <div className='admin_user_card_page'>
    <AdminTab tabIndex={AdminTabConfig.Users.index}/>
    <AdminUsersTable/>
  </div>
}
