import './style.css'
import AdminAgreementCreate from '../../../../components/agreement/admin/AdminAgreementCreate/AdminAgreementCreate'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'

export default function AdminAgreementCreatePage() {
  return <div className='admin_agreement_create_page'>
    <AdminTab tabIndex={AdminTabConfig.Agreements.index}/>
    <AdminAgreementCreate/>
  </div>
}
