import "./style.css"
import { useParams } from "react-router-dom"
import OrderCreateV2 from "../../../../components/order/user/UserOrderCreateV2/OrderCreateV2"
import { useEffect, useState } from "react"
import { OrderV2 } from "../../../../models/order/OrderV2"
import { orderGetV2 } from "../../../../api/order/order_v2_api"

export default function UserOrderCreateV2Page() {
  const { orderId } = useParams()
  const [order, setOrder] = useState<OrderV2 | undefined>()

  useEffect(() => {
    mutateOrder()
  }, [orderId])

  function mutateOrder(): void {
    if (orderId || (order && order?.order_id)) {
      console.log("Getting order: " + orderId || (order && order?.order_id))
      orderGetV2(orderId || (order && order?.order_id)!!)
        .then((order: OrderV2) => {
          setOrder(order)
        })
    } else {
      console.log(`orderId=${orderId}, order.order_id=${order?.order_id}`)
    }
  }

  return <div className="user_order_create_v2_page">
    <OrderCreateV2 order={order} setOrder={setOrder} mutateOrder={mutateOrder} />
  </div>
}
