import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { TypographyProps } from "@mui/material/Typography"

interface Props {
  label: string
  value: boolean
  disabled?: boolean
  className?: string
  onChange?: (value: any) => void
  typographyStyle?: TypographyProps
}

export default function Checkbox_({ label, value, disabled, className, onChange, typographyStyle }: Props) {
  return <div className={className}>
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={value} disabled={disabled ? disabled : false} onChange={onChange ? _ => onChange(!value) : undefined} size="small"/>}
        label={label}
        componentsProps={{ typography: typographyStyle }}
      />
    </FormGroup>
  </div>
}
