import './style.css'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'
import AdminAgreementsTable from '../../../../components/agreement/admin/AdminAgreementsTable/AdminAgreementsTable'

export default function AdminAgreementsPage() {
  return <div className='admin_agreements_page'>
    <AdminTab tabIndex={AdminTabConfig.Agreements.index}/>
    <AdminAgreementsTable/>
  </div>
}
