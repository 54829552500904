import './style.css'
import { OrderStatus } from '../../../models/order/OrderStatus'
import OrderTimeline from '../OrderTimeline/OrderTimeline'
import { Link } from 'react-router-dom'
import { ORDER_TRACKS__PATH } from '../../../config/page_paths'
import Text_ from "../../elements/Text/Text"
import { Card, CardContent } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import OpenInNewIcon from '@mui/icons-material/OpenInNew'


interface Props {
  orderId: string
  orderStatus: OrderStatus
  userRate?: number
  driverRate?: number
  driverExtraMiles?: number
  mutateOrder?: () => void
}

export default function OrderShortDetailsWithTimeline({
                                                        orderId, orderStatus, userRate, driverRate, driverExtraMiles, mutateOrder
                                                      }: Props) {
  return <div className='order_short_details_with_timeline'>
    <Card className='order_short_details_with_timeline__card'>
      <CardContent>
        <Text_>
          <div className='order_short_details_with_timeline__card__id'>
            <b>ID:</b> {orderId}
            <IconButton size='small' color='primary' component={Link} to={`${ORDER_TRACKS__PATH}/${orderId}`}
                        target='_blank'>
              <OpenInNewIcon fontSize='inherit'/>
            </IconButton>
          </div>
        </Text_>
        {userRate && <Text_><b>Rate:</b> {userRate} $</Text_>}
        {driverRate && <Text_><b>Rate:</b> {driverRate} $</Text_>}
        {driverExtraMiles && <Text_><b>Extra miles:</b> {driverExtraMiles}</Text_>}
      </CardContent>
    </Card>
    <OrderTimeline orderStatus={orderStatus}/>
  </div>
}
