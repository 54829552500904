import "./style.css"
import { useParams } from "react-router-dom"
import OrderTrackCard from "../../../../components/order/track/OrderTrackCard/OrderTrackCard"

export default function OrderTrackCardPage() {
  const { orderId } = useParams()
  return <div className="order_tack_card_page">
    <OrderTrackCard orderId={orderId as string}/>
  </div>
}
