import { useDebugState } from 'use-named-state'
import Table_ from '../../../elements/Table/Table'
import { AGREEMENT_EFFECTIVE_DATE_WIDTH, AGREEMENT_ID_WIDTH, AGREEMENT_NAME_WIDTH } from '../../../../config/table_witdth_config'
import { useDriverAgreementsActive } from '../../../../api/agreement/driver_agreement_api'
import { userTimeZoneDate } from '../../../../utils/time_utils'

export default function DriverAgreementsTable() {
  const [page, setPage] = useDebugState('page', 0)
  const [pageSize, setPageSize] = useDebugState('pageSize', 10)
  const { data: usersPage } = useDriverAgreementsActive(page, pageSize)
  return <>
    <Table_
      entitiesPage={usersPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath={'/driver/agreements/'}
      config={{
        idKey: 'agreement_id', columns: [{ header: 'Name', entityPath: ['name'], width: AGREEMENT_NAME_WIDTH }, { header: 'Number', entityPath: ['number'], width: AGREEMENT_EFFECTIVE_DATE_WIDTH }, {
          header: 'Effective Date', entityPath: ['effective_date'], processor: (value: string) => userTimeZoneDate(value), width: AGREEMENT_EFFECTIVE_DATE_WIDTH
        }, { header: 'Agreement ID', entityPath: ['agreement_id'], width: AGREEMENT_ID_WIDTH },],
      }}
    />
  </>
}
