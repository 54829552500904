import './style.css'
import Divider_ from '../../../elements/Divider/Divider'
import { useUserAgreementActive } from '../../../../api/agreement/user_agreement_api'
import AgreementCardDocumentInfo from '../../card_info/AgreementCardDocumentInfo/AgreementCardDocumentInfo'
import AgreementCardDocumentView from '../../card_info/AgreementCardDocumentView/AgreementCardDocumentView'
import CircularProgress_ from "../../../elements/CircularProgress/CircularProgress_";

interface Props {
  agreementId: string
}

export default function UserAgreementCard({ agreementId }: Props) {
  const { data: agreement } = useUserAgreementActive(agreementId)
  return <>
    {agreement ? <div className='user_agreement_card__fields'>
      <Divider_ text={`Agreement overview: ${agreementId}`} className='user_agreement_card__divider__overview'/>
      <AgreementCardDocumentInfo agreement={agreement}/>
      <AgreementCardDocumentView agreement={agreement}/>
    </div> : <CircularProgress_/>}
  </>
}
