import './style.css'
import { meBecomeManager, meBecomeManagerCreateAccount, meBecomeManagerCreateAccountLink } from '../../../../api/user/user_api'
import Button_, { ButtonStyle } from '../../../elements/Button/Button'
import Divider_ from '../../../elements/Divider/Divider'
import { useUser } from '../../../../pages/Layout/Layout'
import { MAIN__PATH } from '../../../../config/page_paths'
import { useNavigate } from 'react-router-dom'
import { BaseAgreement } from '../../../../models/agreement/BaseAgreement'
import { getBackendUrl } from '../../../../config/env'
import { useDebugState } from 'use-named-state'
import Title from "../../../order/user/UserOrderCreateV2/Title"
import { useEffect } from "react"
import { authOtpRequest, authRequest } from "../../../../api/auth/auth_api"
import { AuthCodeRequest, AuthRequest } from "../../../../models/auth/Auth"
import Cookies from "js-cookie"
import OtpInput_ from "../../../elements/OtpInput/OtpInput"
import GoogleIcon_ from "../../../elements/icons/GoogleIcon"
import Text_ from "../../../elements/Text/Text"
import TextField_ from "../../../elements/TextField/TextField"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { useManagerAgreementsActive } from "../../../../api/agreement/manager_agreement_api"

const OTP_LENGTH = 6

export default function UserBecomeManager() {

  const navigate = useNavigate()
  const [formComplete, setFormComplete] = useDebugState<boolean>('formComplete', false)

  const { data: agreementsPage } = useManagerAgreementsActive()
  const [agreementAcknowledged, setAgreementAcknowledged] = useDebugState<boolean>('agreementAcknowledged', false)

  function onAgreementChange(newValue: boolean) {
    if (!newValue) {
      setAgreementAcknowledged(false)
      setFormComplete(false)
    } else {
      setAgreementAcknowledged(true)
      setFormComplete(true)
    }
  }

  const { user, mutateUser } = useUser()

  useEffect(() => {
    if (user === undefined) {
      const token = Cookies.get('jwt_token')
      if (token) {
        localStorage.setItem('token', token)
        mutateUser && mutateUser()
      }
    }
  }, [user])

  const [email, setEmail] = useDebugState<string | undefined>('email', undefined)
  const [authButtonDisabled, setAuthButtonDisabled] = useDebugState<boolean>('authButtonDisabled', true)

  function handleOnEmailChange(value: string) {
    setEmail(value)
    if (value !== '') {
      setAuthButtonDisabled(false)
      localStorage.setItem('email', value)
    } else {
      setAuthButtonDisabled(true)
      localStorage.setItem('email', value)
    }
  }

  const [isOtpUsed, setIsOtpUsed] = useDebugState<boolean>('isOtpUsed', false)

  function handleAuthRequest(event: any) {
    event.preventDefault()
    if (!authButtonDisabled) {
      authRequest({ 'email': email } as AuthRequest)
      setIsOtpUsed(true)
    } else {
      console.error('Auth button should be disabled. Set email first')
    }
  }


  const [code, setCode] = useDebugState<string | undefined>('code', undefined)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useDebugState<boolean>('submitButtonDisabled', true)


  function handleSubmitOtp() {
    const email = localStorage.getItem('email')
    authOtpRequest({ email: email, code: code } as AuthCodeRequest)
      .then((response) => {
        const token = response.token
        if (token) {
          localStorage.setItem('token', token)
          mutateUser && mutateUser()
        } else {
        }
      })
      .catch(e => {
        console.log('AuthOtpView.handleSubmitOtp: Error: ' + JSON.stringify(e))
      })
  }

  function handleOtpChange(value: string) {
    setCode(value)
    if (value.length === OTP_LENGTH) {
      setSubmitButtonDisabled(false)
    } else {
      setSubmitButtonDisabled(true)
    }
  }

  function buildContent() {
    if (!user) {
      return <>
        <div style={{
          display: "flex", flexDirection: "column", rowGap: "0.5em", paddingTop: "0.25em", paddingBottom: "1.5em",
        }}>
          <Title text="All right, let’s sign in / sign up" className="user_become_manager__title" />
          <Button_ sx={{ margin: "auto" }} size={'large'} buttonStyle={ButtonStyle.CONTAINED} href={getBackendUrl() + '/login?redirect_to=' + window.location.href}
                   startIcon={<GoogleIcon_ />}>Sign in with Google</Button_>
          {isOtpUsed ? <>
            <Text_ content="Enter One-Time Password to sign in" variant="subtitle1" paragraph sx={{ textAlign: 'center', margin: "auto" }} />
            <OtpInput_ value={code} onChange={handleOtpChange} length={OTP_LENGTH} />
            <Button_ label="Sign In" sx={{ margin: "auto" }} buttonStyle={ButtonStyle.CONTAINED} disabled={submitButtonDisabled} onClickVoid={handleSubmitOtp} />
          </> : <>
            <Text_ sx={{ margin: "auto" }} variant="subtitle1">Enter email to receive a One-Time Password</Text_>
            <TextField_ style={{ margin: "auto" }} label="Email" variant="outlined" onChange={e => handleOnEmailChange(e.target.value)} value={email} shrink={true}
                        className="login_page__email" />
            <Button_ label="Request One-Time Password" sx={{ margin: "auto" }} size="large" buttonStyle={ButtonStyle.CONTAINED} disabled={authButtonDisabled} onClickAny={handleAuthRequest}
                     className="login_page__otp_button" />
          </>}
        </div>
      </>
    } else if (user.stripe_data?.account_id === undefined) {
      return <div className="user_become_manager__apply">
        <Text_ className="user_become_manager__apply__description">Take the opportunity to become an iUjex Manager</Text_>
        <div className="user_become_manager__agreement">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox value={agreementAcknowledged} onChange={_ => onAgreementChange(!agreementAcknowledged)} size="small" />}
              label="By clicking 'Apply' I have read and agreed with the following Terms and Conditions:"
              componentsProps={{ typography: { fontSize: '0.75em' } }}
            />
          </FormGroup>
          {agreementsPage?.content?.map((agreement: BaseAgreement, i: number) => {
            return <a href={`${getBackendUrl()}/manager/agreements/${agreement?.agreement_id}/file`} style={{ fontSize: '0.75em' }} key={i}>{agreement?.name}</a>
          })}
        </div>
        <Button_
          label="Apply"
          disabled={!agreementAcknowledged}
          onClick={() => meBecomeManagerCreateAccount(window.location.href, agreementsPage?.content?.map(e => e.agreement_id) || []).then(user => {
            const account_onboarding_url = user.stripe_data?.account_onboarding_url
            if (account_onboarding_url !== undefined) {
              window.location.href = account_onboarding_url
            }
          }).then(() => mutateUser && mutateUser())}
          logPath="BecomeManager.meCreateAccount"
          className="user_become_manager__apply__apply"
        />
        <Button_
          label="Not interested"
          buttonStyle={ButtonStyle.RED_OUTLINED}
          onClick={() => meBecomeManager(false).then(() => navigate(MAIN__PATH))}
          logPath="BecomeManager.meBecomeManager?value=false"
          className="user_become_manager__apply__not_interested"
        />
      </div>
    } else if (user?.stripe_data?.account_onboarding_url) {
      return <div className="user_become_manager__continue">
        <Text_ className="user_become_manager__continue__description">
          Your Stripe account is not fully configured.<br />
          Please complete your Stripe account to become a Manager
        </Text_>
        <Button_
          label="Continue setup"
          onClick={() => meBecomeManagerCreateAccountLink(window.location.href)
            .then(user => {
              const account_onboarding_url = user.stripe_data?.account_onboarding_url
              if (account_onboarding_url !== undefined) {
                window.location.href = account_onboarding_url
              }
            })
            .then(() => mutateUser && mutateUser())}
          logPath="UserAccountOnboardingPage.meCreateAccountLink"
          className="user_become_manager__continue__setup"
        />
      </div>
    }
  }

  return <div className="user_become_manager">
    {user?.manager_status === undefined && <Divider_ text="Become a Manager" className="user_become_manager__divider__common_info" />}
    {buildContent()}
  </div>
}
