import './style.css'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import TextField_ from '../../../elements/TextField/TextField'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import Divider_ from '../../../elements/Divider/Divider'
import { toUiOrderStatus } from '../../../../utils/order_status_utils'

interface Props {
  order: BaseOrder
}

export default function OrderCardBaseInfo({ order }: Props) {
  return <>
    <Divider_ text='Base info' className='order_card__divider__base_info'/>
    <TextField_ label='Create at' value={userTimeZoneDate(order.created_at)} className='order_card__created_at'/>
    <TextField_ label='Updated at' value={userTimeZoneDate(order.updated_at)} className='order_card__updated_at'/>
    <TextField_ label='User ID' value={order.user_id} className='order_card__user_id'/>
    <TextField_ label='Status' value={toUiOrderStatus(order.status)} className='order_card__status'/>
  </>
}
